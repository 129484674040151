import React from "react";
import { PlusOutlined } from "@ant-design/icons";
import { Image, Upload } from "antd";
import { downloadImage, uploadFile } from "../services/api";

class ImageUploader extends React.Component {
  getItemById(uid) {
    return this.state.fileList.filter((item) => item.uid === uid)[0];
  }

  reloadFileList() {
    this.setState({
      fileList: this.state.fileList,
    });
  }

  updateFileFollowRemoteResponse(uid, response) {
    var media = response.data.media;
    var item = this.getItemById(uid);
    item.url = media.url;
    item.id = media.id;
    item.status = "done";
    this.reloadFileList();
    var finalFileList = this.state.fileList.map((item) => ({
      id: item.id,
      url: item.url,
    }));
    this.props.onChange(finalFileList);
  }

  setImages(images) {
    this.setState({
      fileList: images.map((item) => ({
        uid: item,
        status: "uploading",
        percent: 0,
      })),
    });
    images.forEach((image) => {
      downloadImage(image, (progressEvent) => {
        let percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        this.getItemById(image).percent = percentCompleted / 2;
        this.reloadFileList();
      }).then((response) => {
        const file = new File([response], image);
        uploadFile(file, (progressEvent) => {
          let percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          this.getItemById(image).percent = 50 + percentCompleted / 2;
          this.reloadFileList();
        }).then((response) => {
          this.updateFileFollowRemoteResponse(image, response);
        });
      });
    });
  }

  constructor(props) {
    super(props);
    this.state = {
      fileList: props.images?.map((item) => ({
        url: item.url,
      })),
      previewOpen: false,
      previewImage: null,
    };
  }

  handlePreview = (file) => {
    console.log(file.url);
    this.setState({
      previewImage: file.url,
      previewOpen: true,
    });
  };

  handleChange = ({ fileList: newFileList }) => {
    console.log(newFileList);
    this.setState({
      fileList: newFileList,
    });
    var finalFileList = newFileList.map((item) => ({
      id: item.id,
      url: item.url,
    }));
    this.props.onChange(finalFileList);
  };

  uploadButton = (
    <button
      style={{
        border: 0,
        background: "none",
      }}
      type="button"
    >
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </button>
  );

  render() {
    return (
      <>
        <Upload
          listType="picture-card"
          fileList={this.state.fileList}
          onChange={this.handleChange}
          onPreview={this.handlePreview}
          customRequest={(value) => {
            const processingId = value.file.uid;
            this.state.fileList.push({
              uid: processingId,
              status: "uploading",
              percent: 0,
            });
            this.reloadFileList();
            uploadFile(value.file, (progressEvent) => {
              let percentCompleted = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
              this.getItemById(processingId).percent = percentCompleted;
              this.reloadFileList();
            }).then((response) => {
              this.updateFileFollowRemoteResponse(processingId, response);
            });
          }}
        >
          {this.state.fileList.length >= 8 ? null : this.uploadButton}
        </Upload>
        {this.state.previewImage && (
          <Image
            preview={{
              visible: this.state.previewOpen,
              onVisibleChange: (visible) =>
                this.setState({ previewOpen: visible }),
              afterOpenChange: (visible) =>
                !visible && this.setState({ previewImage: null }),
            }}
            src={this.state.previewImage}
          />
        )}
      </>
    );
  }
}
export default ImageUploader;
