import React, { useState } from "react";
import { ViewOnlyField } from "./admin/BookingComponents";

function TextInput({ title, value, onTextChange, required, isViewOnly }) {
  const handleTextChange = (e) => {
    const newText = e.target.value;
    onTextChange(newText);
    if (required && newText.trim() === "") {
      setError(`${title} is required`);
    } else {
      setError("");
    }
  };

  const [error, setError] = useState("");

  return (
    <div className="py-2">
      <label className="text-sm mb-2 text-textSecondary">{title}</label>
      {!isViewOnly && (
        <input
          type="text"
          className="py-2 pl-4 bg-gray-50 border h-[45px] mt-2 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full"
          value={value}
          onChange={handleTextChange}
          required={required}
        />
      )}
      {isViewOnly && <ViewOnlyField text={value} className="h-[45px] mt-2" />}
      {error && (
        <p className="text-red-500 text-sm" style={{ fontStyle: "italic" }}>
          {error}
        </p>
      )}{" "}
    </div>
  );
}

export default TextInput;
