import React from "react";
import { useTranslation } from "react-i18next";
import Image from "../Image";
import { bankQR } from "../../data/constants";
import { formatPrice } from "../../features/utils";
import { FaRegCopy } from "react-icons/fa6";
import { message } from "antd";

export default function BankTransfer({ bookingCode, value, classes }) {
  const { t, i18n } = useTranslation();
  const className = `${classes} `;
  var qr = bankQR.replace("amount_no", value).replace("blahblah", bookingCode);
  const [messageApi, contextHolder] = message.useMessage();

  function CopyableComponent({ component, value }) {
    return (
      <div
        className="flex flex-row hover:cursor-pointer"
        onClick={() => {
          navigator.clipboard.writeText(value);
          messageApi.info(t("copied"));
        }}
      >
        {component}

        <FaRegCopy className="ml-[5px] mt-[7px]" />
      </div>
    );
  }

  return (
    <div className={className}>
      {contextHolder}
      <div className="px-4 py-6 rounded-xl border-[1px] border-[#039200] bg-[#EFFFEF] flex text-base text-black font-bold grid md:grid-cols-2 grid-cols-1 items-center justify-center mx-4">
        <div className="my-auto mx-auto">
          <div className="md:block hidden">
            <p>Techcombank</p>
            <p className="mt-1">Nguyen Van Tien</p>
            <CopyableComponent
              component={<p className="mt-1">19032742021017</p>}
              value="19032742021017"
            />
            <CopyableComponent
              component={
                <p className="mt-1">
                  {t("transfer_note")} {bookingCode}
                </p>
              }
              value={bookingCode}
            />
            <p className="mt-1">Số tiền: {formatPrice(value, i18n.language)}</p>
          </div>
        </div>
        <Image src={qr} className="w-[200px] h-[200px] rounded-lg mx-auto" />
        <div className="md:hidden mx-auto mt-2 flex flex-col items-center">
          <p>Techcombank</p>
          <p className="mt-1">Nguyen Van Tien</p>
          <CopyableComponent
            component={<p className="mt-1">19032742021017</p>}
            value="19032742021017"
          />
          <CopyableComponent
            component={
              <p className="mt-1">
                {t("transfer_note")} {bookingCode}
              </p>
            }
            value={bookingCode}
          />

          <p className="mt-1">Số tiền: {formatPrice(value, i18n.language)}</p>
        </div>
      </div>
      <p className="mx-auto my-4 px-4 py-3 rounded-xl bg-[#C1E1FF] text-sm text-black font-regular w-fit">
        {t("transfer_guide")}
      </p>
    </div>
  );
}
