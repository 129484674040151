import moment from "moment";
import React from "react";
import { dateFormat, serverDisplayDateFormat } from "../data/constants";

export async function parseImagesFromT() {
  const htmlString = await navigator.clipboard.readText();
  var parser = new DOMParser();
  var doc = parser.parseFromString(htmlString, "text/html");
  var images = doc.getElementsByTagName("link");
  var imageUrls = [...images]
    .filter((item) => item.imageSrcset !== undefined && item.imageSrcset !== "")
    .map((item) => item.href.split("?")[0]);
  var hotel = JSON.parse(doc.getElementById("__NEXT_DATA__").textContent).props
    .pageProps.hotel;
  var metas = doc.getElementsByTagName("meta");
  var url = [...metas].filter(
    (item) => item.content && item.content.includes("hotel/detail?spec")
  )[0].content;
  const spec = getParam(url, "spec");
  url = getUrl(url) + "?" + spec;
  var roomListTray = doc.querySelector('[data-testid="room-list-tray"]');
  var hasImage = false;
  var roomsList = [];
  for (const child of roomListTray.children) {
    var childClass = child.getAttribute("class");
    if (childClass.split(" ").length < 3) {
      var title = child.getElementsByTagName("h3")[0].innerHTML;
      var element = child.querySelector('[aria-hidden="false"]');
      var imgs = "";
      if (element) {
        imgs = element.getElementsByTagName("img");
        imgs = [...imgs].map((img) => img.src);
        imgs = imgs.toString();
        hasImage = true;
      }
      console.log(imgs);
      roomsList.push({
        name: title,
        name_en: title,
        extra_data: imgs,
      });
    }
  }
  if (!hasImage) {
    throw new Error();
  }

  return {
    url: url,
    name: hotel.name,
    address: hotel.address,
    images: imageUrls,
    lat: hotel.latitude,
    lng: hotel.longitude,
    city: hotel.city,
    province: hotel.province,
    rooms: roomsList,
  };
}

export function getParam(url, key) {
  return url
    .split("?")[1]
    .split("&")
    .filter((item) => item.startsWith(key))[0];
}

export function getUrl(url) {
  return url.split("?")[0];
}

export const getPriceTypeText = (type, language) => {
  switch (type) {
    case "0":
      return language === "vi" ? "Giá ngày thường" : "Regular price";
    case "1":
      return language === "vi" ? "Giá ngày lễ" : "Holiday price";
    case "2":
      return language === "vi" ? "Giá cuối tuần" : "Weekend price";
    case "3":
      return language === "vi" ? "Giá theo mùa" : "Seasonal price";
    default:
      return "";
  }
};

export const formatPrice = (price, language) => {
  if (language === "vi") {
    return `${price.toLocaleString()} VND`;
  } else {
    return `$${(price / 24000).toFixed(2)}`;
  }
};

export function getHotelAddress(hotel, language) {
  if (language === "vi") {
    return (
      hotel.ward.full_name +
      ", " +
      hotel.district.full_name +
      ", " +
      hotel.province.name
    );
  } else {
    return (
      hotel.ward.full_name_en +
      ", " +
      hotel.district.full_name_en +
      ", " +
      hotel.province.name_en
    );
  }
}

export default function GetDisplayPrice({ price, language }) {
  return (
    <>
      {getPriceTypeText(price.type, language)}:{" "}
      <span className="text-gray-500 text-sm line-through mr-2">
        {formatPrice(price.original_price_vnd, language)}
      </span>
      <span className="text-green-700 font-bold">
        {formatPrice(price.display_price_vnd, language)}
      </span>
    </>
  );
}

export function getTravelokaTodayUrl(url) {
  var index1 = url.indexOf("spec=") + 5;
  var index2 = url.indexOf(".1.HOTEL") - 2;
  var dateInUrl = url.substring(index1, index2);
  var tomorrow = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);
  var nextDay = new Date(new Date().getTime() + 48 * 60 * 60 * 1000);
  var expectDate =
    moment(tomorrow).format(dateFormat) +
    "." +
    moment(nextDay).format(dateFormat);
  return url.replace(dateInUrl, expectDate);
}

export function getNumOfDays(endDate, startDate) {
  let date1 = moment(startDate, serverDisplayDateFormat).toDate();
  let date2 = moment(endDate, serverDisplayDateFormat).toDate();
  return Math.floor((date2 - date1) / (1000 * 60 * 60 * 24));
}

export function getDisplayDate(serverDate) {
  var locale = "vi";
  if (localStorage.getItem("language") === "en") {
    locale = "en";
  }
  return moment(serverDate, serverDisplayDateFormat)
    .toDate()
    .toLocaleString(locale, {
      day: "numeric",
      month: "short",
      year: "numeric",
    });
}

export function getDisplayDateTime(serverDate) {
  var locale = "vi";
  if (localStorage.getItem("language") === "en") {
    locale = "en";
  }
  var dateUTC = moment(serverDate, serverDisplayDateFormat).toDate();
  var dateVN = new Date(dateUTC.getTime() + 7 * 60 * 60 * 1000);
  return dateVN.toLocaleString(locale, {
    day: "numeric",
    month: "short",
    year: "numeric",
    hour: "2-digit",
    minute: "numeric",
  });
}

export function validateEmail(email) {
  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  return emailRegex.test(String(email).toLowerCase());
}
