import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as IcHint } from "../../assets/ic_hint.svg";
import PaymentItem from "../../components/booking/PaymentItem";
import IcVietQR from "../../assets/ic_vietqr.png";
import IcNapas from "../../assets/ic_napas.png";
import BankTransfer from "../../components/booking/BankTransfer";
import { formatPrice } from "../../features/utils";
import VieButton from "../../components/button";
import { BookingStatus } from "../../data/constants";
import { IoIosArrowDown } from "react-icons/io";
import { IoIosArrowUp } from "react-icons/io";
import PaymentResultPolling from "../../components/booking/PaymentResultPolling";
import { postRequest } from "../../services/api";
import LoadingIndicator from "../../components/Loading";
import Modal from "antd/es/modal/Modal";

export default function PaymentInfo({ bookingInfo, isCollapsible, onUpdated }) {
  const METHOD_PAYPAL = 1;
  const METHOD_TRANSFER = 2;
  const [paymentMethod, setPaymentMethod] = useState(METHOD_TRANSFER);
  const [isPollingResult, setIsPollingResult] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isShowingConfirm, setIsShowingConfirm] = useState(false);
  const { t, i18n } = useTranslation();
  const bookingCode = bookingInfo.code;
  var value = bookingInfo.deposit_value_vnd;
  var title = `${t("deposit_title")} ${formatPrice(value, i18n.language)}`;
  if (bookingInfo.status === BookingStatus.sale_confirm_payment) {
    value = bookingInfo.deposit_value_vnd;
    var title = `${t("complete_payment_title")} ${formatPrice(
      value,
      i18n.language
    )}`;
  }
  const [isExpended, setIsExpended] = useState(false);
  if (!isExpended && isCollapsible) {
    return (
      <div
        className="w-full bg-primaryGreen rounded-lg text-base text-white font-thin p-4 relative flex flex-row items-center justify-center hover:cursor-pointer"
        onClick={() => {
          setIsExpended(true);
        }}
      >
        <p className="pr-4">{title}</p>
        <IoIosArrowDown className="fill-white my-auto" />
      </div>
    );
  }

  function confirmPayment() {
    setIsShowingConfirm(false);
    var requestUrl = "";
    if (bookingInfo.status === BookingStatus.sale_confirm_deposit) {
      requestUrl = "/booking/user_confirm_payment";
    } else if (bookingInfo.status === BookingStatus.pending_payment) {
      requestUrl = "/booking/user_confirm_deposit";
    }
    setIsLoading(true);
    postRequest(requestUrl, { code: bookingCode })
      .then((res) => {
        setIsLoading(false);
        onUpdated();
      })
      .catch((er) => {
        setIsLoading(false);
        console.log(er);
      });
  }

  return (
    <div className="w-full">
      {isPollingResult && (
        <PaymentResultPolling bookingInfo={bookingInfo} onChange={() => {}} />
      )}
      {isLoading && <LoadingIndicator />}
      <div className="w-full bg-white rounded-lg">
        <div
          className="w-full bg-primaryGreen rounded-t-lg text-base text-white font-thin p-4 relative flex flex-row items-center justify-center hover:cursor-pointer"
          onClick={() => {
            setIsExpended(false);
          }}
        >
          <p className="pr-4">{title}</p>
          {isCollapsible && <IoIosArrowUp className="fill-white my-auto" />}
        </div>
        <div className="p-4">
          <div className="flex flex-row">
            <IcHint />
            <p className="text-sm text-[#0363BC] ml-2">{t("cancel_policy")}</p>
          </div>
          {/* <p className="text-xl font-bold mt-4">
            {t("payment_method_selection")}
          </p> */}
          {/* <PaymentItem
            title="Paypal"
            logos={[IcPaypal]}
            isSelected={paymentMethod === METHOD_PAYPAL}
            onSelected={() => {
              if (paymentMethod !== METHOD_PAYPAL) {
                setPaymentMethod(METHOD_PAYPAL);
              }
            }}
            expendedContent={
              <InfoText
                className="mx-4 mb-6"
                text={
                  "You will be redirected to the PayPal website to complete your payment."
                }
              />
            }
          />
          <div className="w-full h-[1px] bg-[#E3E3E3]"></div> */}
          <PaymentItem
            title="Bank transfer"
            logos={[IcVietQR, IcNapas]}
            isSelected={paymentMethod === METHOD_TRANSFER}
            onSelected={() => {
              if (paymentMethod !== METHOD_TRANSFER) {
                setPaymentMethod(METHOD_TRANSFER);
              }
            }}
            expendedContent={
              <BankTransfer
                bookingCode={bookingCode}
                value={value}
                classes="mb-4"
              />
            }
          />
        </div>
      </div>
      <div className="w-full bg-white rounded-lg mt-4 p-4">
        <div className="relative">
          <span className="text-lg font-bold">{t("amount_to_pay")}</span>
          <span className="text-lg font-bold absolute right-0 text-primaryGreen">
            {formatPrice(value, i18n.language)}
          </span>
        </div>
        {paymentMethod === METHOD_TRANSFER && (
          <VieButton
            className="mt-4"
            title={t("confirm_payment")}
            onClick={() => {
              setIsShowingConfirm(true);
            }}
          />
        )}
        {paymentMethod === METHOD_PAYPAL && (
          <VieButton
            className="mt-4"
            title="Pay with Paypal"
            onClick={() => {
              setIsPollingResult(true);
            }}
          />
        )}
      </div>
      <Modal
        open={isShowingConfirm}
        title={t("confirm_transfer")}
        footer={[
          <span
            className="text-base text-[#959595] font-medium hover:cursor-pointer"
            onClick={() => {
              setIsShowingConfirm(false);
            }}
          >
            {t("not_transfered")}
          </span>,
          <span
            className="text-base text-orange font-medium hover:cursor-pointer ml-3"
            onClick={confirmPayment}
          >
            {t("transfered")}
          </span>,
        ]}
      >
        <p className="text-sm text-black">{t("confirm_transfer_message")}</p>
      </Modal>
    </div>
  );
}
