import React, { useEffect, useRef, useState, useMemo } from "react";
import {
  Button,
  Form,
  Input,
  Select,
  Checkbox,
  message,
  notification,
  InputNumber,
} from "antd";
import HotelRoom from "./HotelRoom";
import ImageUploader from "./ImageUploader";
import { parseImagesFromT } from "../features/utils";
import { dateFormat, getAgodaUrl, getBookingUrl } from "../data/constants";
import { getHotelByUrl, getRequest, postRequest } from "../services/api";
import moment from "moment";
import dayjs from "dayjs";

const layout = {
  labelCol: {
    span: 5,
  },
};

const Context = React.createContext({
  name: "Default",
});

export default function HotelInfo({ hotelUrl }) {
  const contextValue = useMemo(
    () => ({
      name: "VieStay",
    }),
    []
  );
  const [provinces, setProvinces] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [wards, setWards] = useState([]);
  const [hotelInfo, setHotelInfo] = useState();
  const uploaderRef = useRef();
  const filterOption = (input, option) => {
    return (
      (option?.key ?? "").toLowerCase().includes(input.toLowerCase()) ||
      (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
    );
  };
  var [form] = Form.useForm();

  const onFinish = (values) => {
    var apiPath = "/hotel/create";
    if (hotelUrl) {
      apiPath = "/hotel/update";
      values.id = hotelInfo.id;
    }
    parseLocalPrice(values);
    postRequest(apiPath, values)
      .then((response) => {
        openNotification(response.data.hotel.rewrite_url);
        onReset();
      })
      .catch((error) => {
        message.open({ type: "error", content: "Có lỗi xảy ra" });
        console.log(error);
      });
  };
  function onReset() {
    localStorage.removeItem("hotelForm");
    form.resetFields();
    setHotelInfo(JSON.parse("{}"));
  }

  const onValuesChange = () => {
    saveForm();
  };

  function saveForm() {
    const values = form.getFieldsValue();
    parseLocalPrice(values);
    localStorage.setItem("hotelForm", JSON.stringify(values, null, 2));
  }

  async function getProvinces() {
    return await getRequest("/location/get?level=1")
      .then((response) => {
        var provinces = response.data.locations;
        setProvinces(provinces);
        return provinces;
      })
      .catch((error) => console.log(error));
  }

  function onSelectProvince() {
    form.setFieldValue("district_code", "");
    form.setFieldValue("ward_code", "");
    setDistricts([]);
    setWards([]);
    getDistricts(form.getFieldValue("province_code"));
  }

  function onSelectDistrict() {
    form.setFieldValue("ward_code", "");
    setWards([]);
    getWards(form.getFieldValue("district_code"));
  }

  async function getDistricts(code) {
    return await getRequest("/location/get?level=2&code=" + code)
      .then((response) => {
        var districts = response.data.locations;
        setDistricts(districts);
        return districts;
      })
      .catch((error) => console.log(error));
  }

  async function getWards(code) {
    return await getRequest("/location/get?level=3&code=" + code)
      .then((response) => {
        var wards = response.data.locations;
        setWards(wards);
        return wards;
      })
      .catch((error) => console.log(error));
  }

  function autoSelectLocation(hotelInfo) {
    var selectedProvince = "";
    var selectedDistrict = "";
    // manual correct
    selectedProvince = provinces.filter((place) =>
      comparation(place, hotelInfo.province)
    )[0]?.code;
    if (selectedProvince !== undefined) {
      form.setFieldValue("province_code", selectedProvince);
      getDistricts(selectedProvince).then((districts) => {
        selectedDistrict = districts.filter((place) =>
          comparation(place, hotelInfo.city)
        )[0]?.code;
        if (selectedDistrict !== undefined) {
          form.setFieldValue("district_code", selectedDistrict);
          getWards(selectedDistrict).then((wards) => {
            var selectedWard = wards.filter((place) =>
              findWard(place, hotelInfo.address)
            )[0]?.code;
            if (selectedWard !== undefined) {
              form.setFieldValue("ward_code", selectedWard);
            }
          });
        }
      });
    }
  }

  function findWard(place, address) {
    var compareValue = address.toLowerCase();
    return (
      compareValue.includes(place.full_name_en.toLowerCase()) ||
      compareValue.includes(place.name.toLowerCase()) ||
      compareValue.includes(place.name_en.toLowerCase())
    );
  }

  function comparation(place, placeName) {
    var compareValue =
      placeName === "Hanoi" ? "ha noi city" : placeName.toLowerCase();
    return (
      place.full_name_en.toLowerCase() === compareValue ||
      place.name.toLowerCase() === compareValue ||
      place.name_en.toLowerCase() === compareValue
    );
  }

  function parseUrl() {
    parseImagesFromT()
      .then((value) => {
        if (uploaderRef.current !== undefined) {
          uploaderRef.current.setImages(value.images);
        }
        form.setFieldValue("traveloka_url", value.url);
        form.setFieldValue("agoda_url", getAgodaUrl(value.name));
        form.setFieldValue("booking_url", getBookingUrl(value.name));
        form.setFieldValue("address", value.address);
        form.setFieldValue("name", value.name);
        form.setFieldValue("rooms", value.rooms);
        autoSelectLocation(value);
        saveForm();
        setHotelInfo(value);
      })
      .catch((error) => {
        console.log(error);
        message.error("Định dạng dữ liệu không đúng");
      });
  }

  const [api, contextHolder] = notification.useNotification();

  const openNotification = (hotelUrl) => {
    api.info({
      message: `Cập nhật khách sạn thành công`,
      btn: (
        <Button
          type="link"
          size="small"
          onClick={() => window.open(`/hotel/${hotelUrl}`, "_blank")}
        >
          KIỂM TRA LẠI
        </Button>
      ),
      placement: "bottom",
    });
  };

  useEffect(() => {
    const getAddressInfo = (hotelInfo) => {
      var provincode = hotelInfo.province_code;
      if (hotelInfo.province) {
        provincode = hotelInfo.province.code;
        hotelInfo.province_code = provincode;
        hotelInfo.district_code = hotelInfo.district.code;
        hotelInfo.ward_code = hotelInfo.ward.code;
      }

      getProvinces().then((response) => {
        if (provincode) {
          getDistricts(provincode).then((response) => {
            if (hotelInfo.district_code) {
              getWards(hotelInfo.district_code).then((res) => {
                form.setFieldsValue(hotelInfo);
              });
            }
          });
        }
      });
    };

    if (hotelUrl) {
      getHotelByUrl(hotelUrl)
        .then((response) => {
          parseRemotePrice(response);
          setHotelInfo(response);
          getAddressInfo(response);
        })
        .catch((error) => {});
    } else {
      form.resetFields();
      var info = JSON.parse(localStorage.getItem("hotelForm") ?? "{}");
      parseRemotePrice(info);
      setHotelInfo(info);
      getAddressInfo(info);
      form.setFieldsValue(info);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hotelUrl]);

  function parseRemotePrice(hotelInfo) {
    if (hotelInfo.rooms) {
      hotelInfo.rooms.forEach((room) => {
        if (room && room.prices) {
          room.prices.forEach((price) => {
            if (price && price.date_range) {
              const formattedDates = price.date_range.map((date) =>
                dayjs(date, dateFormat)
              );
              price.date_range_local = formattedDates;
            }
          });
        }
      });
    }
  }

  function parseLocalPrice(hotelInfo) {
    if (hotelInfo.rooms) {
      hotelInfo.rooms.forEach((room) => {
        if (room && room.prices) {
          room.prices.forEach((price) => {
            if (price?.type !== "3" && price?.date_range) {
              delete price.date_range;
            } else if (price?.date_range_local) {
              const formattedDates = price.date_range_local?.map((date) =>
                moment(date.toDate()).format(dateFormat)
              );
              price.date_range = formattedDates;
            }
          });
        }
      });
    }
  }

  if (hotelUrl && hotelInfo === undefined) {
    return <></>;
  }
  return (
    <Context.Provider value={contextValue}>
      {contextHolder}
      <Form
        {...layout}
        form={form}
        onFinish={onFinish}
        className="max-w-[700px] bg-appBg rounded-xl px-4 py-4 "
        onValuesChange={onValuesChange}
        labelAlign="left"
      >
        <Button className="mb-4" onClick={() => parseUrl()}>
          Xuất Dữ liệu
        </Button>

        <Form.Item
          name="traveloka_url"
          label="Traveloka"
          rules={[
            {
              required: true,
            },
          ]}
          className="flex-1"
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="booking_url"
          label="Booking.com"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="agoda_url"
          label="Agoda"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>

        <div className="flex flex-row">
          <Form.Item
            name="name"
            labelCol={{ span: 7 }}
            label="Tên khách sạn"
            className="w-[500px]"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <div className="w-4"></div>
          <Form.Item
            name="recommended"
            label="Recommended"
            valuePropName="checked"
            className="w-[180px]"
            labelCol={{
              span: 20,
            }}
          >
            <Checkbox />
          </Form.Item>
        </div>
        <Form.Item name="sort_index" label="Thứ tự ưu tiên">
          <InputNumber />
        </Form.Item>
        <Form.Item
          name="address"
          label="Địa chỉ"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <div className="flex flex-row">
          <Form.Item
            name="province_code"
            label="Tỉnh/thành"
            labelCol={{ span: 10 }}
            className="w-[350px]"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select
              showSearch
              filterOption={filterOption}
              onSelect={onSelectProvince}
            >
              {provinces.map((item) => (
                <Select.Option key={item.code} value={item.code}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <div className="w-4"></div>
          <Form.Item
            name="district_code"
            label="Quận/huyện"
            labelCol={{ span: 12 }}
            labelAlign="right"
            className="w-[350px]"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select
              showSearch
              filterOption={filterOption}
              onSelect={onSelectDistrict}
            >
              {districts.map((item) => (
                <Select.Option key={item.code} value={item.code}>
                  {item.full_name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </div>
        <Form.Item
          name="ward_code"
          label="Phường/Xã"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select showSearch filterOption={filterOption}>
            {wards.map((item) => (
              <Select.Option key={item.code} value={item.code}>
                {item.full_name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <div className="flex flex-row">
          <Form.Item
            name="email"
            label="Email"
            labelCol={{ span: 10 }}
            className="w-[350px]"
            rules={[
              {
                type: "email",
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <div className="w-4"></div>
          <Form.Item
            name="phone"
            label="Phone"
            labelCol={{ span: 12 }}
            labelAlign="right"
            className="w-[350px]"
            rules={[
              {
                required: true,
                message: "Cần nhập số điện thoại hợp lệ",
                pattern: "[0-9]{10}",
              },
            ]}
          >
            <Input type="tel" placeholder="012-345-6789" />
          </Form.Item>
        </div>
        <Form.Item
          noStyle
          shouldUpdate={(prevValues, currentValues) =>
            prevValues.gender !== currentValues.gender
          }
        >
          {({ getFieldValue }) =>
            getFieldValue("gender") === "other" ? (
              <Form.Item
                name="customizeGender"
                label="Customize Gender"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input />
              </Form.Item>
            ) : null
          }
        </Form.Item>

        <Form.Item name="media">
          <ImageUploader
            images={hotelInfo?.media ?? []}
            ref={uploaderRef}
            onChange={(fileList) => {
              form.setFieldValue("media", fileList);
              saveForm();
            }}
          />
        </Form.Item>

        <HotelRoom />
        <div className="flex flex-row items-center mt-6 w-full justify-end">
          <Button type="primary" htmlType="submit" className="mr-4">
            Submit
          </Button>
          <Button htmlType="button" onClick={onReset}>
            Reset
          </Button>
        </div>

        {/* <Form.Item noStyle shouldUpdate>
          {() => <pre>{JSON.stringify(form.getFieldsValue(), null, 2)}</pre>}
        </Form.Item> */}
      </Form>
    </Context.Provider>
  );
}
