import React, { useState, useEffect } from "react";
import TextInput from "../../components/TextInput";
import PhoneInput from "../../components/PhoneInput";
import RangeDatePicker from "../../components/RangeDatePicker";
import MailIcon from "../../assets/icon_email.svg";
import MailIconSelected from "../../assets/ic_email_selected.svg";
import WhatappIcon from "../../assets/ic_whatapp.svg";
import WhatappIconSelected from "../../assets/ic_whatapp_selected.svg";
import TelegramIcon from "../../assets/ic_telegram.svg";
import TelegramIconSelected from "../../assets/ic_tele_selected.svg";
import ZaloIcon from "../../assets/ic_zalo2.svg";
import ZaloIconSelected from "../../assets/ic_zalo_selected.svg";
import PhoneIcon from "../../assets/ic_phone.svg";
import PhoneIconSelected from "../../assets/ic_phone_selected.svg";
import Image from "../../components/Image";
import { useNavigate, useLocation } from "react-router-dom";
import { createBooking } from "../../services/api";
import { message, Spin } from "antd";
import { UserBookingRoom } from "../../components/ConfirmRoom";
import { useTranslation } from "react-i18next";
import { analytics } from "../../firebase/firebase";

// Define booking methods enum-like structure
const BookingMethods = {
  EMAIL: {
    type: "email",
    icon: MailIcon,
    selected: MailIconSelected,
    label: "Email",
  },
  WHATSAPP: {
    type: "whatsapp",
    icon: WhatappIcon,
    selected: WhatappIconSelected,
    label: "WhatsApp",
  },
  TELEGRAM: {
    type: "telegram",
    icon: TelegramIcon,
    selected: TelegramIconSelected,
    label: "Telegram",
  },
  ZALO: {
    type: "zalo",
    icon: ZaloIcon,
    selected: ZaloIconSelected,
    label: "Zalo",
  },
  PHONE: {
    type: "phone",
    icon: PhoneIcon,
    selected: PhoneIconSelected,
    label: "Phone",
  },
};

const UserBookingInfo = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const rewrite_url = searchParams.get("rewrite_url");
  const [bookingMethod, setBookingMethod] = useState(
    () => localStorage.getItem("BI_bookingMethod") || BookingMethods.EMAIL.type
  );
  const [fullName, setFullName] = useState(
    () => localStorage.getItem("BI_fullName") || ""
  );

  const [email, setEmail] = useState(
    () => localStorage.getItem("BI_email") || ""
  );

  const [phoneNumber, setPhoneNumber] = useState(
    () => localStorage.getItem("BI_phoneNumber") || ""
  );

  const [countryCode, setCountryCode] = useState(() =>
    localStorage.getItem("BI_countryCode") ||
    localStorage.getItem("language") === "vi"
      ? "+84"
      : "1"
  );

  const [dateRange, setDateRange] = useState(
    () => localStorage.getItem("BI_dateRange") || []
  );

  const [loadingReserve, setLoadingReserve] = useState(false);
  const { t } = useTranslation();

  const hotel = JSON.parse(localStorage.getItem(rewrite_url));
  const bookingInfo = JSON.parse(
    localStorage.getItem(rewrite_url + "_bookingInfo")
  );
  const roomIds = Object.keys(bookingInfo);
  const selectedRooms = hotel.rooms.filter((room) => {
    return roomIds.includes(room.id.toString());
  });

  useEffect(() => {
    localStorage.setItem("BI_bookingMethod", bookingMethod);
  }, [bookingMethod]);

  useEffect(() => {
    localStorage.setItem("BI_fullName", fullName);
  }, [fullName]);

  useEffect(() => {
    localStorage.setItem("BI_email", email);
  }, [email]);

  useEffect(() => {
    localStorage.setItem("BI_phoneNumber", phoneNumber);
  }, [phoneNumber]);

  useEffect(() => {
    localStorage.setItem("BI_countryCode", countryCode);
  }, [countryCode]);

  useEffect(() => {
    localStorage.setItem("BI_dateRange", dateRange);
  }, [dateRange]);

  const handleBookingMethodChange = (method) => {
    setBookingMethod(method);
  };

  const validateEmail = (email) => {
    if (!email) {
      return false;
    }
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return emailRegex.test(String(email).toLowerCase());
  };

  const handleFullNameChange = (newFullName) => {
    setFullName(newFullName);
  };

  const handleDateRangeChange = (dates) => {
    setDateRange(dates);
  };

  const handlePhoneChange = (phone) => {
    setPhoneNumber(phone);
  };

  const handleCountryCodeChange = (code) => {
    setCountryCode(code);
  };

  const handleEmailChange = (newEmail) => {
    setEmail(newEmail);
  };

  const handleReserve = async () => {
    let errorMessage = "";
    analytics.logEvent("click", {
      source: "reserve_booking",
      hotel_name: hotel.name,
    });
    // Validate input data based on booking method
    if (!fullName) {
      errorMessage += t("invalid_fullname");
    }

    const selectedBookingMethod = BookingMethods[bookingMethod.toUpperCase()];
    if (selectedBookingMethod.type === BookingMethods.EMAIL.type) {
      if (!validateEmail(email)) {
        errorMessage += t("invalid_email");
      }
    } else {
      if (!phoneNumber) {
        errorMessage += t("invalid_phone");
      }
    }

    if (!dateRange || dateRange.length !== 2) {
      errorMessage += t("invalid_date");
    }

    if (errorMessage) {
      message.error(errorMessage);
      return;
    }

    let customerPhone = null;
    if (selectedBookingMethod.type !== BookingMethods.EMAIL.type) {
      customerPhone = countryCode + phoneNumber;
    }

    setLoadingReserve(true);

    // Prepare booking data
    const bookingData = {
      hotel_id: hotel.id,
      contact_type: selectedBookingMethod.type,
      customer_name: fullName,
      start_date: dateRange[0],
      end_date: dateRange[1],
    };

    bookingData.rooms = roomIds.map((id) => {
      return { room_id: Number(id), quantity: bookingInfo[id] };
    });

    // Conditionally add the customer_phone key based on the selectedBookingMethod.type
    if (selectedBookingMethod.type !== "email") {
      bookingData.customer_phone = customerPhone;
    } else {
      bookingData.customer_email = email;
    }

    try {
      // Call the API to create the booking
      var bookingResponse = await createBooking(bookingData);
      gtag_report_conversion(bookingResponse);
    } catch (error) {
      message.error(t("something_went_wrong"));
      console.error("Error creating booking:", error);
    } finally {
      setLoadingReserve(false);
    }
  };

  function gtag_report_conversion(bookingResponse) {
    window.dataLayer = window.dataLayer || [];
    function gtag() {
        window.dataLayer.push(arguments);
    }
    var callback = function () {
      navigate(`/bookingConfirm/${bookingResponse.data.booking.code}`);
    };
    gtag('event', 'conversion', {
        'send_to': 'AW-981181903/ap4gCLyF2swZEM_L7tMD',
        'value': 1.0,
        'currency': 'USD',
        'event_callback': callback
    });
    return false;
  }

  const { media } = hotel;
  const mainImage = media && media.length > 0 ? media[0] : null;

  return (
    <div className="container mx-auto max-w-[1024px] p-8 h-full">
      <div className="relative">
        <h2 className="text-lg font-bold">{t("bookingInfo.info")}</h2>

        <div className="lg:hidden md:hidden mt-4  mb-8">
          {/* Card view for small screens */}
          <div className="w-auto bg-white rounded-lg p-4 relative text-sm md:text-base">
            <div className="p-2 text-sm md:text-base">
              <h3 className="font-semibold mb-2">{hotel.name}</h3>
            </div>
            <div className="rounded-lg">
              <Image
                src={mainImage.url}
                alt={mainImage.url}
                className="aspect-[3/1] w-full h-[200px] object-cover rounded-lg"
              />
            </div>
            {selectedRooms.map((selectedRoom, index) => (
              <UserBookingRoom
                selectedRoom={selectedRoom}
                bookingInfo={bookingInfo}
                key={index}
              />
            ))}
          </div>
        </div>
      </div>
      <div className="flex w-full space-x-4 mt-4">
        <div className="w-full bg-white rounded-lg p-4 relative text-sm md:text-base h-fit">
          <p>{t("bookingInfo.info-title")}</p>
          <p className="text-lg font-bold text-black">
            {BookingMethods[bookingMethod.toUpperCase()].label}
          </p>
          <div className="flex items-center my-4">
            {Object.values(BookingMethods).map((method) => (
              <button
                key={method.type}
                className={`mr-2 ${
                  bookingMethod === method.type ? "bg-gray-50" : "bg-gray-200"
                }`}
                onClick={() => handleBookingMethodChange(method.type)}
              >
                <img
                  alt=""
                  src={
                    bookingMethod === method.type
                      ? method.selected
                      : method.icon
                  }
                />
              </button>
            ))}
          </div>
          <div>
            <label className="block text-sm mb-2 text-textSecondary">
              {t("check-in")} - {t("check-out")}
            </label>
            <RangeDatePicker
              className="mb-2 pb-[10px]"
              onDateRangeChange={handleDateRangeChange}
            />
            <TextInput
              title={t("bookingInfo.fullname")}
              value={fullName}
              onTextChange={handleFullNameChange}
              required
            ></TextInput>
            {bookingMethod === "email" && (
              <TextInput
                title="Email"
                value={email}
                onTextChange={handleEmailChange}
                required
              ></TextInput>
            )}
            {bookingMethod !== "email" && (
              <PhoneInput
                phoneNumber={phoneNumber}
                countryCode={countryCode}
                onPhoneNumberChange={handlePhoneChange}
                onCountryCodeChange={handleCountryCodeChange}
              />
            )}
            <button
              className="w-full mt-4 bg-orange text-white font-bold py-2 px-4 rounded"
              onClick={handleReserve}
            >
              {loadingReserve ? <Spin /> : t("bookingInfo.reserve")}
            </button>
          </div>
        </div>
        <div className="hidden md:block lg:block mb-8">
          {/* Card view for large screens */}
          <div className="w-auto bg-white rounded-lg p-4 relative text-sm md:text-base">
            <div className="p-2 text-sm md:text-base">
              <h3 className="font-semibold mb-2">{hotel.name}</h3>
            </div>
            <div className="rounded-lg">
              <Image
                src={mainImage.url}
                alt={mainImage.url}
                className="aspect-[3/1] w-full h-[200px] object-cover rounded-lg"
              />
            </div>
            {selectedRooms.map((selectedRoom, index) => (
              <UserBookingRoom
                selectedRoom={selectedRoom}
                bookingInfo={bookingInfo}
                key={index}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserBookingInfo;
