import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Image as ImagePreview, InputNumber, message } from "antd";
import { getHotelByUrl } from "../services/api";
import PriceCheckHorizontal from "../components/PricingCheckHorizontal";
import PriceCheckVertical from "../components/PricingCheckVertical";
import icLocation from "../assets/ic_location.svg";
import Skeleton from "../components/Skeleton";
import Image from "../components/Image";
import { useTranslation } from "react-i18next";
import BookingRoom from "../components/BookingRoom.js";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import { analytics } from "../firebase/firebase";

const LoadingSkeleton = () => {
  return (
    <div className="container max-w-[1024px] mx-auto flex flex-row">
      <div className="flex-1 mx-auto px-4">
        {/* Title Header */}
        <Skeleton className="w-[300px] my-8 h-4" />

        {/* Main Image */}
        <Skeleton className="rounded-2xl mb-2 object-cover w-full aspect-[2/1]" />
        {/* Images */}
        <div className="flex flex-row">
          <Skeleton className="rounded-lg w-[160px] h-[110px] mr-4" />
          <Skeleton className="rounded-lg w-[160px] h-[110px] mr-4" />
          <Skeleton className="rounded-lg w-[160px] h-[110px] mr-4 mt-1 hidden md:block" />
          <Skeleton className="rounded-lg w-[160px] h-[110px] mt-1 hidden lg:block" />
        </div>

        {/* Address with Icon */}
        <Skeleton className="w-[300px] my-4 h-4" />
        <Skeleton className="w-3/4 h-[100px] block lg:hidden mx-auto my-4" />

        {/* Rooms */}

        <Skeleton className="w-full, h-36" />
      </div>

      <Skeleton className="w-[300px] h-[100px] mt-20 lg:block hidden" />
    </div>
  );
};

const HotelDetail = () => {
  const { rewrite_url } = useParams();
  const [hotel, setHotel] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [visible, setVisible] = useState(false);
  const [previewIndex, setPreviewIndex] = useState(0);
  const { t, i18n } = useTranslation();
  const { pathname } = useLocation();

  useEffect(() => {
    const fetchHotel = async () => {
      try {
        setLoading(true);
        const hotelData = await getHotelByUrl(rewrite_url);
        analytics.logEvent("view_hotel", { hotel_name: hotelData.name });
        setHotel(hotelData);
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    fetchHotel();
  }, [rewrite_url]);

  const handleImageClick = (index) => {
    setVisible(true);
    setPreviewIndex(index);
  };

  if (loading) {
    return <LoadingSkeleton />;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!hotel) {
    return null;
  }

  const { name, address, rooms, media } = hotel;

  const mainImage = media.length > 0 ? media[0] : null;
  const [, ...images] = media;
  var bookingInfo = {};

  function openBookingPage() {
    analytics.logEvent("click", {
      source: "detail_booking",
      hotel_name: hotel.name,
    });
    var isValid = false;
    var values = Object.values(bookingInfo);
    if (values) {
      values.forEach((quantity) => {
        if (quantity > 0) {
          isValid = true;
        }
      });
    }
    if (!isValid) {
      message.info(t("select-room-quantity"));
      return;
    }
    localStorage.setItem(hotel.rewrite_url, JSON.stringify(hotel));
    localStorage.setItem(
      hotel.rewrite_url + "_bookingInfo",
      JSON.stringify(bookingInfo)
    );
    window.open("/bookinginfo?rewrite_url=" + hotel.rewrite_url, "_self");
  }
  const description = `${t("meta.title.hotel-detail")} ${name}`;
  return (
    <div className="container max-w-[1024px] mx-auto flex flex-row">
      <Helmet>
        <title property="og:title">{t("meta.title.home")}</title>
        <meta
          name="description"
          property="og:description"
          content={description}
        ></meta>
        <meta property="og:image" content={mainImage?.url} />
        <link rel="canonical" href={`https://viestay.com${pathname}`} />
      </Helmet>
      <div className="mx-auto px-4">
        {/* Title Header */}
        <h1 className="text-lg md:text-xl lg:text-2xl font-bold my-4">
          {name}
        </h1>

        {/* Main Image */}
        <Image
          src={mainImage && mainImage.url}
          key={mainImage?.id}
          onClick={() => handleImageClick(0)}
          className="rounded-2xl mb-2 object-cover w-full aspect-[2/1] hover:cursor-pointer"
        />

        {/* Images */}

        <div className="flex flex-rows overflow-x-auto no-scrollbar">
          {images.map((item, index) => (
            <Image
              key={item.id}
              className="h-[110px] w-[160px] object-cover rounded-lg flex-shrink-0 mr-2 hover:cursor-pointer"
              src={item.url}
              alt={`Hotel Small ${item.id}`}
              onClick={() => handleImageClick(index + 1)}
            />
          ))}
        </div>

        <ImagePreview.PreviewGroup
          style={{
            display: "none",
          }}
          preview={{
            visible,
            current: previewIndex,
            onVisibleChange: (value) => {
              if (!value) {
                setVisible(false); // Close the gallery when it's not visible
              }
              setVisible(value);
            },
            onChange: (cur, pre) => {
              setPreviewIndex(cur);
            },
          }}
        >
          <ImagePreview
            src={mainImage && mainImage.url}
            style={{
              display: "none",
            }}
          />
          {images.map((item, index) => (
            <ImagePreview
              style={{
                display: "none",
              }}
              src={item.url}
              key={index}
            />
          ))}
        </ImagePreview.PreviewGroup>

        {/* Address with Icon */}
        <div className="flex items-center mb-4 mt-4 text-sm lg:text-base">
          <img alt="" src={icLocation} />
          <p>{address}</p>
        </div>

        <PriceCheckHorizontal className="block lg:hidden" hotel={hotel} />

        {/* Rooms */}
        <div className="grid grid-cols-1 gap-4 mb-8">
          {rooms.map((room, index) => (
            <BookingRoom
              room={room}
              index={index}
              onNumberChange={(value) => {
                bookingInfo[`${room.id}`] = value;
              }}
              rewrite_url={hotel?.rewrite_url}
              onRequestBooking={openBookingPage}
              key={index}
            />
          ))}
        </div>
      </div>
      <PriceCheckVertical className="lg:block hidden" hotel={hotel} />
    </div>
  );
};

export default HotelDetail;
