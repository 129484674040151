import React from "react";
import { ReactComponent as CheckBoxSelected } from "../../assets/checkbox-selected.svg";
import { ReactComponent as CheckBoxDefault } from "../../assets/checkbox-default.svg";

export default function PaymentItem({
  title,
  logos,
  expendedContent,
  isSelected,
  onSelected,
  className,
}) {
  return (
    <div className={className}>
      <div
        className="flex flex-row h-[70px] relative hover:cursor-pointer"
        onClick={() => {
          onSelected();
        }}
      >
        {isSelected && <CheckBoxSelected className="my-auto" />}
        {!isSelected && <CheckBoxDefault className="my-auto" />}
        <p className="text-bold text-base text-black ml-4 my-auto font-bold">
          {title}
        </p>
        <div className="flex flex-row absolute h-[70px] right-0 items-center">
          {logos.map((logo) => (
            <img src={logo} className="ml-3" key={logo} />
          ))}
        </div>
      </div>
      {isSelected && expendedContent}
    </div>
  );
}
