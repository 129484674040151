import React, { useState } from "react";
import TextInput from "../TextInput";
import ComfirmPriceComponent from "./ConfirmPriceComponent";
import DropDownSelect from "./DropDownSelect";
import { Button, message } from "antd";
import { validateEmail } from "../../features/utils";
import { postRequest } from "../../services/api";
import LoadingIndicator from "../Loading";

export default function AdminBookingPendingConfirmation({
  bookingInfo,
  onChange,
}) {
  var email = "";
  const languageOptions = [
    { value: "Tiếng Việt", key: "vi" },
    { value: "Tiếng Anh", key: "en" },
  ];
  var selectedLanguage = languageOptions[0];
  const [isLoading, setIsLoading] = useState(false);

  function confirmBooking() {
    if (validateEmail(email)) {
      setIsLoading(true);
      postRequest("/booking/confirm", {
        code: bookingInfo.code,
        confirm_email: email,
        language: selectedLanguage.key,
        rooms: bookingInfo.rooms,
      })
        .then((response) => {
          setIsLoading(false);
          onChange();
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
          message.open({ type: "error", content: err.toString() });
        });
    } else {
      message.open({ type: "error", content: "Email không hợp lệ" });
    }
  }

  function deleteBooking() {
    setIsLoading(true);
    postRequest("/booking/cancel", {
      code: bookingInfo.code,
    })
      .then((response) => {
        setIsLoading(false);
        onChange();
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
        message.open({ type: "error", content: err.toString() });
      });
  }

  return (
    <div className="w-[350px]">
      {isLoading && <LoadingIndicator />}
      {bookingInfo.rooms.map((room, index) => (
        <ComfirmPriceComponent
          room={room}
          key={index}
          onChange={(room) => {}}
        />
      ))}
      <TextInput
        title="Email khách hàng"
        onTextChange={(text) => {
          email = text;
        }}
      />
      <DropDownSelect
        title={"Ngôn ngữ khi gửi mail"}
        selections={languageOptions}
        onSelect={(option) => {
          selectedLanguage = option;
        }}
      />
      <Button
        className="w-full h-[45px] bg-pendingPayment text-white text-sm mt-6"
        onClick={() => {
          confirmBooking();
        }}
      >
        Gửi báo giá
      </Button>
      <Button
        className="w-full h-[45px] text-white text-sm mt-6"
        danger
        onClick={() => {
          deleteBooking();
        }}
      >
        Hủy
      </Button>
    </div>
  );
}
