// About.js
import React from "react";
import about2 from "../assets/about_us_2-min.jpg";
import about3 from "../assets/about_us_3-min.jpg";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

const About = () => {
  const { t } = useTranslation();
  return (
    <div className="container mx-auto">
      <Helmet>
        <title>{t("meta.title.about")}</title> {/* Set the title dynamically */}
      </Helmet>
      <div className="h-[150px] bg-about1st justify-center flex flex-col">
        <p className="text-[34px] ml-6 text-white">
          {t("about")} <span className="text-orange">VieStay</span>
        </p>
      </div>
      <div className="py-4 bg-about2nd items-center flex flex-row">
        <p className="text-lg ml-6 w-full">
          {t("first_line")}
          <br />
          <br />
          {t("second_line")}
        </p>
        <img
          className="min-h-[200px] max-h-[300px] aspect-square object-cover hidden md:block"
          src={about2}
          alt=""
        />
      </div>
      <div className="h-[600px] bg-about3rd flex flex-row">
        <div className="w-full px-4 mt-8">
          <p className="font-medium text-xl">{t("contact_us")}</p>
          <p className="text-gray-400">{t("contact_guide")}</p>
          <div className="flex flex-col items-center mt-10">
            <p>Facebook</p>
            <a
              href="https://www.facebook.com/profile.php?id=61559224967813"
              className="text-gray-400 underline"
            >
              VieStay
            </a>
            <p className="mt-4">Phone Number</p>
            <a href="tel:84973480166" className="text-gray-400 underline">
              +84973480166
            </a>
            <p className="mt-4">Email</p>
            <a
              href="mailto:info@viestay.com "
              className="text-gray-400 underline"
            >
              info@viestay.com
            </a>
            <p className="mt-4">Zalo</p>
            <a
              href="https://zalo.me/84973480166"
              className="text-gray-400 underline"
            >
              +84973480166
            </a>
            <p className="mt-4">Telegram</p>
            <a
              href="tg://resolve?domain=viestay_vn"
              className="text-gray-400 underline"
            >
              VieStay_VN
            </a>
            <p className="mt-4">Whatapp</p>
            <a
              href="whatsapp://send?text=Hello World!&phone=+84973480166"
              className="text-gray-400 underline"
            >
              +84973480166
            </a>
            <p className="mt-4">Office Address</p>
            <p className="text-gray-400 underline">
              Số 14, ngõ 1 Ngọc Lâm, Long Biên, Hà Nội
            </p>
          </div>
        </div>
        <img
          className="h-[500px] w-auto object-cover hidden md:block"
          src={about3}
          alt=""
        />
      </div>
    </div>
  );
};

export default About;
