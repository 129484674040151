import React from "react";
import TextInput from "../../components/TextInput";
import Image from "../../components/Image";
import { UserConfirmRoom } from "../../components/ConfirmRoom";
import { InfoText } from "../../components/admin/BookingComponents";
import { getDisplayDate } from "../../features/utils";
import { useTranslation } from "react-i18next";

export default function UserBookingPendingInfo({ bookingInfo }) {
  const { t } = useTranslation();
  console.log(bookingInfo.hotel_name);
  return (
    <div className="container mx-auto max-w-[1024px] p-8">
      <div className="relative">
        <h2 className="text-lg font-bold">{t("bookingInfo.info")}</h2>

        <div className="lg:hidden md:hidden mt-4">
          {/* Card view for small screens */}
          <div className="w-auto bg-white rounded-lg p-4 relative text-sm md:text-base">
            <div className="p-2 text-sm md:text-base">
              <h3 className="font-semibold mb-2">{bookingInfo.hotel_name}</h3>
            </div>
            <div className="rounded-lg">
              <Image
                src={bookingInfo.hotel_first_image}
                className="aspect-[3/1] w-full h-[200px] object-cover rounded-lg"
              />
            </div>
            {bookingInfo.rooms.map((selectedRoom, index) => (
              <UserConfirmRoom selectedRoom={selectedRoom} key={index} />
            ))}
          </div>
        </div>
      </div>
      <div className="flex w-full space-x-4 mt-4">
        <div>
          <p className="mb-3">
            {t("bookingInfo.info-title")}
            <span className="font-bold text-black">
              {" "}
              {bookingInfo.contact_type.toUpperCase()}
            </span>
          </p>
          <div className="w-full bg-white rounded-lg p-4 relative text-sm md:text-base">
            <div>
              <TextInput
                title={`${t("check-in")} - ${t("check-out")}`}
                value={`${getDisplayDate(
                  bookingInfo.start_date
                )} - ${getDisplayDate(bookingInfo.end_date)}`}
                isViewOnly={true}
              />
              <TextInput
                title={t("bookingInfo.fullname")}
                value={bookingInfo.customer_name}
                isViewOnly={true}
              />
              {bookingInfo.contact_type === "email" && (
                <TextInput
                  title="Email"
                  value={bookingInfo.customer_email}
                  isViewOnly={true}
                />
              )}
              {bookingInfo.contact_type !== "email" && (
                <TextInput
                  title="Phone"
                  value={bookingInfo.customer_phone}
                  isViewOnly={true}
                />
              )}
            </div>
          </div>
          <InfoText text={t("booking_confirm_guide")} className="mt-4" />
        </div>
        <div className="hidden md:block lg:block mt-[35px]">
          {/* Card view for large screens */}
          <div className="w-auto bg-white rounded-lg p-4 relative text-sm md:text-base">
            <div className="p-2 text-sm md:text-base">
              <h3 className="font-semibold mb-2">{bookingInfo.hotel_name}</h3>
            </div>
            <div className="rounded-lg">
              <Image
                src={bookingInfo.hotel_first_image}
                className="aspect-[3/1] w-full h-[200px] object-cover rounded-lg"
              />
            </div>
            {bookingInfo.rooms.map((selectedRoom, index) => (
              <UserConfirmRoom selectedRoom={selectedRoom} key={index} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
