import React, { useState, useRef, useEffect } from "react";
import { ReactComponent as IconArrowDown } from "../assets/arrow_down.svg";
import countryCodes from "../data/CountryCodes.js";
import { useTranslation } from "react-i18next";

function PhoneInput({
  phoneNumber: defaultPhoneNumber,
  countryCode: defaultCountryCode,
  onCountryCodeChange,
  onPhoneNumberChange,
}) {
  const [phoneNumber, setPhoneNumber] = useState(defaultPhoneNumber || "");
  const [countryCode, setCountryCode] = useState(defaultCountryCode || "+1");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [filteredCountryCodes, setFilteredCountryCodes] =
    useState(countryCodes);
  const { t } = useTranslation();

  const dropdownRef = useRef(null);

  useEffect(() => {
    setPhoneNumber(defaultPhoneNumber || "");
  }, [defaultPhoneNumber]);

  useEffect(() => {
    setCountryCode(defaultCountryCode || "+1");
  }, [defaultCountryCode]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  const handlePhoneNumberChange = (e) => {
    const input = e.target.value;
    // Validate input to allow only numbers
    if (/^\d*$/.test(input) || input === "") {
      setPhoneNumber(input);
      if (onPhoneNumberChange) {
        onPhoneNumberChange(input);
      }
    }
  };

  const handleCountryCodeChange = (code) => {
    setCountryCode(code);
    if (onCountryCodeChange) {
      onCountryCodeChange(code);
    }
    toggleDropdown();
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleSearch = (e) => {
    const searchTerm = e.target.value.toLowerCase();
    const filteredCodes = countryCodes.filter(
      (country) =>
        country.name.toLowerCase().includes(searchTerm) ||
        country.code.toLowerCase().includes(searchTerm)
    );
    setFilteredCountryCodes(filteredCodes);
  };

  return (
    <div className="container relative py-2">
      <label className="block mb-2 text-sm font-medium text-gray-900">
        {t("bookingInfo.phonenumber")}
      </label>
      <div className="flex">
        <button
          onClick={toggleDropdown}
          className="flex w-[70px] h-[45px] left-0 mr-2 text-sm px-2 font-medium text-gray-900 bg-gray-100 border border-gray-300 rounded-lg hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-blue-500 items-center"
        >
          <label className="pr-[10px]">{countryCode}</label>
          <IconArrowDown />
        </button>
        {dropdownOpen && (
          <div
            ref={dropdownRef}
            className={`absolute top-[45px] max-h-52 z-10 w-52 bg-white divide-y divide-gray-100 rounded-lg shadow-md border border-gray-200 overflow-y-auto `}
          >
            <input
              type="text"
              placeholder="Search..."
              onChange={handleSearch}
              className="w-full px-4 p-2.5 text-sm text-gray-800 border-b border-gray-200 focus:outline-none"
            />
            {filteredCountryCodes.map((country, index) => (
              <button
                key={index}
                type="button"
                className="w-full px-4 p-2.5 text-sm text-gray-800 hover:bg-gray-100 hover:text-gray-900"
                onClick={() => handleCountryCodeChange(country.code)}
              >
                {country.name} ({country.code})
              </button>
            ))}
          </div>
        )}
        <input
          type="text"
          id="phone-input"
          value={phoneNumber}
          pattern="[0-9]*"
          inputMode="numeric"
          onChange={handlePhoneNumberChange}
          className="block w-full p-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 h-[45px]"
        />
      </div>
    </div>
  );
}

export default PhoneInput;
