import React, { useEffect, useReducer } from "react";
import { Spin, Popconfirm, message } from "antd";
import { useState } from "react";
import { postRequest, searchHotels } from "../services/api";
import Image from "../components/Image";
import { CiEdit } from "react-icons/ci";
import { MdDeleteOutline } from "react-icons/md";

export default function SearchPage({ keyword, onSelectHotel }) {
  const STATUS_EMPTY = 2;
  const STATUS_LOADING = 0;
  const STATUS_RESULT = 1;
  const [status, setStatus] = useState(STATUS_LOADING);
  const [hotels, setHotels] = useState([]);
  const [, forceUpdate] = useReducer((x) => x + 1, 0);

  useEffect(() => {
    if (keyword !== "") {
      searchHotels(keyword)
        .then((responseHotels) => {
          if (responseHotels.length === 0) {
            setStatus(STATUS_EMPTY);
          } else {
            setStatus(STATUS_RESULT);
          }
          setHotels(responseHotels);
        })
        .catch((error) => {
          setStatus(STATUS_EMPTY);
        });
    }
  }, [keyword]);

  function deleteHotel(hotel) {
    postRequest("/hotel/delete", { id: hotel.id })
      .then((response) => {
        const index = hotels.indexOf(hotel);
        console.log(index);

        if (index > -1) {
          hotels.splice(index, 1);
          forceUpdate();
        }
        message.open({ type: "info", content: "Xóa thành công" });
      })
      .catch((error) => {
        console.log(error);
      });
  }
  return (
    <>
      {keyword && status === STATUS_LOADING && (
        <div className="w-full flex">
          <Spin size="large" className="mx-auto"></Spin>
        </div>
      )}
      {keyword && status === STATUS_EMPTY && (
        <div className="w-full flex">
          <p className="text-md mx-auto text-gray-600">
            Không có kết quả phù hợp
          </p>
        </div>
      )}
      {keyword && status === STATUS_RESULT && (
        <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
          {hotels.map((hotel) => (
            <div className="rounded-lg flex flex-col relative bg-white">
              <div
                onClick={() => {
                  window.open(`/hotel/${hotel.rewrite_url}`, "_self");
                }}
              >
                <div className="rounded-lg p-1">
                  <Image
                    src={hotel.first_image}
                    alt={hotel.name}
                    className="aspect-[5/3] w-full object-cover rounded-lg"
                  />
                </div>
                <div className="p-2 text-sm md:text-base">
                  <h3 className="font-semibold mb-2 h-10">{hotel.name}</h3>
                  <p className="text-gray-600 mb-2">
                    {hotel.ward.name}, {hotel.district.name},{" "}
                    {hotel.province.name}
                  </p>
                </div>
              </div>
              <div className="w-full h-[1px] bg-gray-100"> </div>
              <div className="w-full h-10 flex flex-row items-center ">
                <CiEdit
                  className="flex-1 my-auto h-5 w-5 fill-gray-500 hover:cursor-pointer"
                  onClick={() => {
                    onSelectHotel(hotel.rewrite_url);
                  }}
                ></CiEdit>

                <div className="h-6 my-auto w-[1px] bg-gray-100"></div>
                <Popconfirm
                  title="Xóa khách sạn"
                  description="Bạn xác nhận muốn xóa khách sạn này?"
                  onConfirm={() => deleteHotel(hotel)}
                  okText="Yes"
                  cancelText="No"
                >
                  <MdDeleteOutline className="flex-1 my-auto h-5 w-5 fill-gray-500 hover:cursor-pointer"></MdDeleteOutline>
                </Popconfirm>
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  );
}
