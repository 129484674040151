import React, { useState } from "react";
import { Button, message } from "antd";
import RoomInfoByAdmin from "./BookingComponents";
import { postRequest } from "../../services/api";
import LoadingIndicator from "../Loading";

export default function AdminBookingUserConfirmPayment({
  bookingInfo,
  onChange,
}) {
  var [isLoading, setIsLoading] = useState(false);

  function confirmBooking() {
    setIsLoading(true);
    postRequest("/booking/finish", {
      code: bookingInfo.code,
    })
      .then((response) => {
        setIsLoading(false);
        onChange();
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
        message.open({ type: "error", content: err.toString() });
      });
  }

  return (
    <div className="w-[350px]">
      {isLoading && <LoadingIndicator />}
      <RoomInfoByAdmin bookingInfo={bookingInfo} />

      <Button
        className="w-full h-[45px] bg-purple text-white text-sm mt-6"
        onClick={() => {
          confirmBooking();
        }}
      >
        Đã nhận full
      </Button>
    </div>
  );
}
