import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { BookingStatus } from "../data/constants";
import BookingItemInfo from "../components/booking/BookingItemInfo";
import AdminBookingPendingConfirmation from "../components/admin/AdminBookingPendingConfirmation";
import AdminBookingPendingPayment from "../components/admin/AdminBookingPendingPayment";
import AdminBookingUserConfirmDeposit from "../components/admin/AdminBookingUserConfirmDeposit";
import AdminBookingSaleConfirmDeposit from "../components/admin/AdminBookingSaleConfirmDeposit";
import AdminBookingNoAction from "../components/admin/AdminBookingNoAction";
import LoadingIndicator from "../components/Loading";
import { getRequest } from "../services/api";
import { message } from "antd";
import AdminBookingUserConfirmPayment from "../components/admin/AdminBookingUserConfirmPayment";

export default function ConfirmBooking() {
  const [searchParams] = useSearchParams();
  let code = searchParams.get("code");
  var [bookingItem, setBookingItem] = useState(null);
  var [isLoading, setIsLoading] = useState(false);
  var status = bookingItem?.status;
  function reload() {
    setIsLoading(true);
    getRequest("/booking/get?code=" + code)
      .then((response) => {
        setIsLoading(false);
        var booking = response.data.booking;
        setBookingItem(booking);
      })
      .catch((error) => {
        setIsLoading(false);
        message.open({ type: "error", content: error.toString() });
        console.log(error);
      });
  }

  useEffect(() => {
    if (!bookingItem) {
      reload();
    }
  }, [code]);
  return (
    <div className="relative">
      {isLoading && <LoadingIndicator />}
      {bookingItem && (
        <div className="container mx-auto py-4 px-4 grid grid-cols-1 md:grid-cols-2 flex-1 mb-4 gap-10">
          <BookingItemInfo
            bookingInfo={bookingItem}
            isAdmin={true}
            isSimpleUI={false}
          />
          {status === BookingStatus.pending_confirmation && (
            <AdminBookingPendingConfirmation
              bookingInfo={bookingItem}
              onChange={() => {
                reload();
              }}
            />
          )}
          {status === BookingStatus.pending_payment && (
            <AdminBookingPendingPayment
              bookingInfo={bookingItem}
              onChange={() => {
                reload();
              }}
            />
          )}
          {status === BookingStatus.user_confirm_deposit && (
            <AdminBookingUserConfirmDeposit
              bookingInfo={bookingItem}
              onChange={() => {
                reload();
              }}
            />
          )}
          {status === BookingStatus.sale_confirm_deposit && (
            <AdminBookingSaleConfirmDeposit
              bookingInfo={bookingItem}
              onChange={() => {
                reload();
              }}
            />
          )}
          {status === BookingStatus.user_confirm_payment && (
            <AdminBookingUserConfirmPayment
              bookingInfo={bookingItem}
              onChange={() => {
                reload();
              }}
            />
          )}
          {status === BookingStatus.canceled && (
            <AdminBookingNoAction bookingInfo={bookingItem} />
          )}
          {status === BookingStatus.done && (
            <AdminBookingNoAction bookingInfo={bookingItem} />
          )}
        </div>
      )}
    </div>
  );
}
