import React from "react";
import bookingHeader from "../../assets/booking_header_bg.png";
import { ReactComponent as HotelIcon } from "../../assets/hotel_icon.svg";
import { ReactComponent as AvatarIcon } from "../../assets/icon_avatar.svg";
import { ReactComponent as People } from "../../assets/people_2.svg";
import { ReactComponent as Price } from "../../assets/price_tag.svg";
import { useTranslation } from "react-i18next";
import {
  formatPrice,
  getDisplayDate,
  getDisplayDateTime,
  getNumOfDays,
} from "../../features/utils";
import { BookingStatus } from "../../data/constants";

export default function BookingItemInfo({
  bookingInfo,
  isAdmin,
  isSimpleUI,
  onSelect,
}) {
  const { t } = useTranslation();
  const numOfDays = getNumOfDays(bookingInfo.end_date, bookingInfo.start_date);
  return (
    <>
      <div
        className="h-fit flex flex-col w-[350px] rounded-2xl bg-white relative hover:cursor-pointer"
        onClick={() => {
          if (onSelect) {
            onSelect(bookingInfo);
          }
        }}
      >
        <div id="header" className="w-full h-[70px] flex flex-col">
          <img
            className="w-[350px] h-[70px] absolute rounded-t-2xl"
            src={bookingHeader}
          ></img>
          <HotelIcon className="my-auto ml-[15px] z-10" />
          <div className="h-[70px] z-9 flex flex-col absolute pl-[80px] item-center justify-center">
            <GetTitle isAdmin={isAdmin} bookingInfo={bookingInfo} />
            {!isAdmin && <GetUserStatusUI bookingID={bookingInfo.code} />}
            {isAdmin && <GetAdminStatusUI status={bookingInfo.status} />}
          </div>
        </div>
        <div className="w-[350px] py-4 flex flex-col">
          <p className="text-base font-bold pl-4">{bookingInfo.hotel_name}</p>
          <div className="mt-3 relative flex flex-col">
            <div className="mt-2 ml-4">
              <p className="text-sm text-[#687176]">{t("check-in")}</p>
              <p className="text-sm font-bold mt-1">
                {getDisplayDate(bookingInfo.start_date)}
              </p>
            </div>
            <div className="w-[1px] h-[55px] bg-[#e6e6e6] absolute left-[175px]"></div>
            <div className="mt-1 absolute left-[190px]">
              <p className="text-sm text-[#687176]">{t("check-out")}</p>
              <p className="text-sm font-bold mt-1">
                {getDisplayDate(bookingInfo.end_date)}
              </p>
            </div>
          </div>
          <div className="w-full mt-6 px-4 relative">
            {isAdmin && (
              <>
                <div className="w-full h-[1px] bg-[#e6e6e6]"></div>
                <p className="text-sm font-bold mt-3">Thời gian</p>
                <p className="text-sm mt-2 text-textSecondary">
                  Tạo: {getDisplayDateTime(bookingInfo.create_time)}
                </p>
                <p className="text-sm mt-2">
                  <span className="text-textSecondary">
                    Cập nhật: {getDisplayDateTime(bookingInfo.update_time)} -{" "}
                  </span>
                  {bookingInfo.last_updated_user && (
                    <span className="text-purple font-medium">
                      {bookingInfo.last_updated_user.username}
                    </span>
                  )}
                </p>
              </>
            )}
            {!isSimpleUI && (
              <>
                <div className="w-full h-[1px] bg-[#e6e6e6] mb-3 mt-3"></div>
                {bookingInfo.rooms.map((room, index) => (
                  <RoomInfo room={room} key={index} numOfDays={numOfDays} />
                ))}
              </>
            )}
            <div className="w-full h-[1px] bg-[#e6e6e6] mt-3"></div>
            <p className="text-sm font-bold mt-3">{t("customer_info")}: </p>
            <div className="flex flex-row mt-3 items-center">
              <AvatarIcon />
              <div className="ml-4">
                <p className="text-sm mt-1 text-textSecondary">
                  {bookingInfo.customer_name}
                </p>
                {bookingInfo.contact_type !== "email" && (
                  <p className="text-sm mt-1 text-textSecondary">
                    {bookingInfo.contact_type} {bookingInfo.customer_phone}
                  </p>
                )}
                <p className="text-sm mt-1 text-textSecondary">
                  {bookingInfo.customer_email}
                </p>
              </div>
            </div>

            {bookingInfo.status !== BookingStatus.pending_confirmation &&
              !isSimpleUI && <TotalPrice bookingInfo={bookingInfo} />}
          </div>
        </div>
      </div>
    </>
  );
}

function TotalPrice({ bookingInfo }) {
  const { t, i18n } = useTranslation();
  return (
    <>
      <div className="w-full h-[1px] bg-[#e6e6e6] mt-3"></div>
      <p className="text-sm font-bold mt-3">{t("price_summary")}: </p>
      <div></div>
      <div className="text-sm text-userConfirm font-bold mt-2 relative">
        <p>{t("total_price")}</p>
        <p className="absolute right-0 top-0">
          {formatPrice(bookingInfo.display_total_value_vnd, i18n.language)}
        </p>
      </div>
    </>
  );
}

function GetTitle({ isAdmin, bookingInfo }) {
  const { t } = useTranslation();
  var content = "";
  if (isAdmin) {
    content = `Code: ${bookingInfo.code}`;
  } else {
    content = t("hotel_summary");
  }
  return <p className="text-base font-bold">{content}</p>;
}

function RoomInfo({ room, numOfDays }) {
  const { t, i18n } = useTranslation();
  var roomName = `(${room.quantity}x) ${room.name}`;
  var quantity = `${room.max_people} ${t("Booking-NumberGuest")}`;
  var roomPrice;
  if (room.unit_price_vnd) {
    roomPrice = `${formatPrice(room.display_unit_price_vnd, i18n.language)}${t(
      "night_room"
    )}`;
  }
  var numOfDaysText = ` x ${numOfDays} ${t("night")}`;
  return (
    <div>
      <p className="text-sm font-bold text-[#003AA9] mt-2">{roomName}</p>
      <div className="flex flex-row mt-2">
        <People /> <p className="ml-2 text-sm text-[#687176]">{quantity}</p>
      </div>
      {roomPrice && (
        <div className="flex flex-row mt-2">
          <Price />{" "}
          <p className="ml-2 text-sm text-[#687176]">
            {roomPrice}
            <span className="font-bold text-[#003AA9]">{numOfDaysText}</span>
          </p>
        </div>
      )}
    </div>
  );
}

function GetAdminStatusUI({ status }) {
  if (status === BookingStatus.pending_confirmation)
    return (
      <p className="text-sm text-white font-medium mt-1">
        Status: Chờ xác nhận
      </p>
    );
  else if (status === BookingStatus.pending_payment)
    return (
      <p className="text-sm text-pendingPayment font-medium mt-1">
        Status: Chờ thanh toán
      </p>
    );
  else if (status === BookingStatus.user_confirm_deposit)
    return (
      <p className="text-sm text-userConfirm font-medium mt-1">
        Status: User xác nhận cọc
      </p>
    );
  else if (status === BookingStatus.sale_confirm_deposit)
    return (
      <p className="text-sm text-confirmDeposit font-medium mt-1">
        Status: Đã nhận cọc
      </p>
    );
  else if (status === BookingStatus.user_confirm_payment)
    return (
      <p className="text-sm text-confirmDeposit font-medium mt-1">
        Status: User xác nhận full
      </p>
    );
  else if (status === BookingStatus.done)
    return (
      <p className="text-sm text-complete font-medium mt-1">
        Status: Hoàn thành
      </p>
    );
  else if (status === BookingStatus.canceled)
    return (
      <p className="text-sm text-canceled font-medium mt-1">Status: Đã hủy</p>
    );
}

function GetUserStatusUI({ bookingID }) {
  return (
    <p className="text-sm text-[#6C6C6C] font-medium mt-1">
      BookingID: {bookingID}
    </p>
  );
}
