import React, { useEffect, useState } from "react";
import { Layout, Menu } from "antd";
import { RiHotelLine } from "react-icons/ri";
import { MdOutlineMarkEmailRead as emailIcon } from "react-icons/md";
import ManageHotel from "./ManageHotel";
import ConfirmBooking from "./ConfirmBooking";
import BookingList from "./BookingList";
import { useParams, useSearchParams } from "react-router-dom";

const { Sider } = Layout;

const items = [
  {
    key: 1,
    icon: React.createElement(RiHotelLine),
    label: "Khách sạn",
  },
  {
    key: 2,
    icon: React.createElement(emailIcon),
    label: "Booking",
  },
];

export default function AdminPage() {
  var now = new Date().getTime();
  var loginTime = localStorage.getItem("loginTime") ?? 0;
  let { page } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  let bookingCode = searchParams.get("code");
  let defaultKey = "1";
  if (page === "booking") {
    if (bookingCode) {
      defaultKey = "3";
    } else {
      defaultKey = "2";
    }
  }
  const [key, setKey] = useState(defaultKey);

  useEffect(() => {
    if (page === "booking") {
      if (bookingCode) {
        setKey("3");
      } else {
        setKey("2");
      }
    }
  });
  if (now - loginTime > 28800000) {
    window.open("/admin/login", "_self");
    return;
  }

  function onSelect(item) {
    if (item.key === "2") {
      window.open("/admin/booking", "_self");
    } else {
      window.open("/admin/hotel", "_self");
    }
  }
  return (
    <Layout className="m-0 p-0 h-lvh flex flex-row bg-appBg overflow-y-auto">
      <Sider breakpoint="lg" collapsedWidth="0" className="fixed h-lvh">
        <div className="h-12 bg-gray-800 justify-center flex content-center">
          <p className="text-gray-500 my-auto">
            {localStorage.getItem("userName")}
          </p>
        </div>
        <Menu
          className="mt-4"
          theme="dark"
          mode="inline"
          defaultSelectedKeys={[defaultKey]}
          items={items}
          onSelect={onSelect}
        />
      </Sider>

      <div className="flex flex-1">
        {key === "1" && (
          <ManageHotel className="container mx-auto py-8 px-4 flex flex-col flex-1" />
        )}
        {key === "2" && (
          <BookingList
            onSelect={(bookingItem) => {
              localStorage.setItem(
                "admin_booking_item_" + bookingItem.code,
                JSON.stringify(bookingItem)
              );
              window.open("/admin/booking?code=" + bookingItem.code, "_self");
            }}
          />
        )}
        {key === "3" && <ConfirmBooking />}
      </div>
    </Layout>
  );
}
