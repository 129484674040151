import React, { useState, useRef, useEffect } from "react";
import { ReactComponent as IconArrowDown } from "../../assets/arrow_down.svg";

export default function DropDownSelect({ title, onSelect, selections }) {
  const [selectedValue, setSelectedValue] = useState(selections[0].value);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const dropdownRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  const handleSelectChange = (selected) => {
    setSelectedValue(selected.value);
    if (onSelect) {
      onSelect(selected);
    }
    toggleDropdown();
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  return (
    <div className="container relative py-2">
      <label className="text-sm text-textSecondary">{title}</label>
      <div className="flex">
        <button
          onClick={toggleDropdown}
          className="flex mt-3  px-4 h-[45px] left-0 mr-2 text-sm font-medium text-gray-900 border border-gray-300 rounded-lg hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-blue-500 items-center"
        >
          <label className="pr-[10px] text-sm">{selectedValue}</label>
          <IconArrowDown />
        </button>
        {dropdownOpen && (
          <div
            ref={dropdownRef}
            className="absolute top-[45px] max-h-52 z-10 w-52 bg-white divide-y divide-gray-100 rounded-lg shadow-md border border-gray-200 overflow-y-auto"
          >
            {selections.map((option, index) => (
              <button
                key={index}
                type="button"
                className="w-full px-4 p-2.5 text-sm text-gray-800 hover:bg-gray-100 hover:text-gray-900"
                onClick={() => handleSelectChange(option)}
              >
                {option.value}
              </button>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}
