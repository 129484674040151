import React, { useState } from "react";

const Image = ({ src, className, key2, onClick }) => {
  const [loading, setLoading] = useState(true);
  const handleLoadFinish = () => {
    if (loading) {
      setLoading(false);
    }
  };
  const loadingStyle = loading
    ? `animate-pulse bg-placeholder ${className}`
    : className;
  return (
    <img
      key={key2}
      onClick={onClick}
      src={src}
      className={loadingStyle}
      alt=""
      loading="lazy"
      onLoad={handleLoadFinish}
    ></img>
  );
};

export default Image;
