import React, { useState } from "react";
import TextInput from "../TextInput";
import { Button, message } from "antd";
import RoomInfoByAdmin from "./BookingComponents";
import { postRequest } from "../../services/api";
import { formatPrice } from "../../features/utils";
import LoadingIndicator from "../Loading";

export default function AdminBookingPendingPayment({ bookingInfo, onChange }) {
  var hotelConfirmCode;
  var [isLoading, setIsLoading] = useState(false);

  function confirmBooking() {
    if (hotelConfirmCode) {
      setIsLoading(true);
      postRequest("/booking/sale_confirm_deposit", {
        code: bookingInfo.code,
        reservation_code: hotelConfirmCode,
      })
        .then((response) => {
          setIsLoading(false);
          onChange();
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
          message.open({ type: "error", content: err.toString() });
        });
    } else {
      message.open({
        type: "error",
        content: "Mã nhận phòng không được để trống",
      });
    }
  }

  function deleteBooking() {
    setIsLoading(true);
    postRequest("/booking/cancel", {
      code: bookingInfo.code,
    })
      .then((response) => {
        setIsLoading(false);
        onChange();
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
        message.open({ type: "error", content: err.toString() });
      });
  }

  return (
    <div className="w-[350px]">
      {isLoading && <LoadingIndicator />}
      <RoomInfoByAdmin bookingInfo={bookingInfo} />

      <p className="text-sm mt-2 text-textSecondary">Số tiền cọc</p>
      <p className="pt-[10px] w-[350px] h-[45px] rounded-md border-[1px] border-[#D9D9D9] pl-[10px] bg-[#E6E6E6] mt-2">
        {formatPrice(bookingInfo.deposit_value_vnd, "vi")}
      </p>
      <TextInput
        title="Mã nhận phòng"
        onTextChange={(text) => {
          hotelConfirmCode = text;
        }}
      />
      <Button
        className="w-full h-[45px] bg-confirmDeposit text-white text-sm mt-6"
        onClick={() => {
          confirmBooking();
        }}
      >
        Gửi mail xác nhận
      </Button>
      <Button
        className="w-full h-[45px] text-white text-sm mt-6"
        danger
        onClick={() => {
          deleteBooking();
        }}
      >
        Hủy
      </Button>
    </div>
  );
}
