// API.js
import axios from "axios";

const API_URL = localStorage.getItem("host") ?? "https://api.viestay.com"; // Replace with your API URL

// Dev Environment
// const API_URL = "https://warm-dove-polished.ngrok-free.app";

const instance = axios.create({
  withCredentials: true,
  baseURL: API_URL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

export async function getRequest(path) {
  try {
    const response = await instance.get(path);
    return response.data;
  } catch (error) {
    console.error("Error calling api:", path, error);
    throw error;
  }
}

export async function downloadImage(url, progressCallback) {
  try {
    const response = await axios({
      url,
      responseType: "blob",
      method: "GET",
      onDownloadProgress: progressCallback,
    });
    return response.data;
  } catch (error) {
    console.error("Error calling api:", url, error);
  }
}

export async function uploadFile(file, progressCallback) {
  try {
    const formData = new FormData();
    formData.append("file", file);
    const config = {
      onUploadProgress: progressCallback,
      withCredentials: true,
    };
    var uploadAPI = API_URL + "/media/upload";
    const response = await axios.post(uploadAPI, formData, config);
    return response.data;
  } catch (error) {
    console.error("Error calling api media/upload", error);
  }
}

export async function postRequest(path, body) {
  try {
    const response = await instance.post(path, body);
    return response.data;
  } catch (error) {
    console.error("Error calling api:", path, error);
    throw error;
  }
}

// Post search Hotel
export async function searchHotels(keyword, location, recommended, page_no) {
  try {
    const requestBody = {};

    // Add keyword to the request body if it's not null
    if (keyword !== null) {
      requestBody.keyword = keyword;
    }
    // Add location to the request body if it's not null
    if (location !== null) {
      requestBody.location = location;
    }
    // Add recommended to the request body if it's not null
    if (recommended !== null) {
      requestBody.recommended = recommended;
    }

    if (keyword) {
      requestBody.page_no = page_no;
      requestBody.page_size = 50;
    }

    const response = await instance.post("/hotel/search", requestBody);
    const { data } = response.data; // Destructure the 'data' object from the response

    // Check if 'data' object exists and has 'hotels' array
    if (data && data.hotels && Array.isArray(data.hotels)) {
      return data.hotels; // Return the list of hotels
    } else {
      throw new Error("Invalid response format or missing data");
    }
  } catch (error) {
    console.error("Error calling /hotel/search", error);
    throw error;
  }
}

// get hotel detail
export async function getHotelByUrl(rewrite_url) {
  try {
    const response = await instance.get(
      `/hotel/get_by_url?rewrite_url=${rewrite_url}`
    );
    const { data } = response.data;
    return data.hotel;
  } catch (error) {
    console.error("Error calling hotel API", error);
    throw error;
  }
}

// post create booking
export async function createBooking(bookingData) {
  try {
    const response = await instance.post("/booking/create", bookingData);
    return response.data;
  } catch (error) {
    console.error("Error calling /booking/create", error);
    throw error;
  }
}
