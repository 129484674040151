import React, { useState, useEffect } from "react";
import { DatePicker } from "antd";
import moment from "moment";
import { useTranslation } from "react-i18next";

const { RangePicker } = DatePicker;

function RangeDatePicker({ defaultDateRange, onDateRangeChange }) {
  const { t } = useTranslation();
  const [selectedDateRange, setSelectedDateRange] = useState([]);

  const disabledDate = (current) => {
    // Disable dates before today
    return current && current < moment().startOf("day");
  };

  useEffect(() => {
    if (defaultDateRange && defaultDateRange.length === 2) {
      const formattedDates = defaultDateRange.map((date) =>
        moment(date, "DD-MM-YYYY")
      );
      console.log(formattedDates);
      setSelectedDateRange(formattedDates);
    }
  }, [defaultDateRange]);

  const handleDateRangeChange = (dates) => {
    setSelectedDateRange(dates); // Update state
    if (onDateRangeChange) {
      const formattedDates = dates.map((date) =>
        moment(date.toDate()).format("DD-MM-YYYY")
      );
      onDateRangeChange(formattedDates);
    }
  };

  return (
    <RangePicker
      picker="date"
      onChange={handleDateRangeChange}
      format="DD-MM-YYYY"
      value={selectedDateRange}
      className="w-auto h-[45px] bg-gray-50"
      placeholder={[t("check-in"), t("check-out")]}
      disabledDate={disabledDate}
    />
  );
}

export default RangeDatePicker;
