import React from "react";
import { Route, useRoutes } from "react-router-dom";

const Sitemap = () => {
  const routes = useRoutes();
  const baseUrl = "https://viestay.com"; // Update with your base URL

  // Generate XML content for each route
  const generateXmlForRoute = (route) => {
    return `
      <url>
        <loc>${baseUrl}${route.path}</loc>
        <lastmod>${new Date().toISOString().split("T")[0]}</lastmod>
        <priority>0.80</priority>
      </url>
    `;
  };

  // Generate XML content for all routes
  const generateSitemapXml = () => {
    const xmlContent = routes
      .map((route) => generateXmlForRoute(route))
      .join("");
    return `<?xml version="1.0" encoding="UTF-8"?>
      <urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">
        ${xmlContent}
      </urlset>`;
  };

  // Serve XML content as a downloadable file
  const downloadSitemap = () => {
    const sitemapContent = generateSitemapXml();
    const blob = new Blob([sitemapContent], { type: "text/xml" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "sitemap.xml";
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  };

  // Trigger downloadSitemap function on component mount
  React.useEffect(() => {
    downloadSitemap();
    console.log("ádadda");
  }, []);

  // Return null to prevent rendering anything in the browser
  return null;
};

export default Sitemap;
