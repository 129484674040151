import React from "react";
import { Dropdown } from "antd";
import unitedKingdomFlag from "../assets/united-kingdom.png"; // English flag image
import vietnamFlag from "../assets/vietnam-flag.png";
import { useTranslation } from "react-i18next";

const items = [
  {
    key: "vi",
    label: "Tiếng Việt",
    icon: (
      <img src={vietnamFlag} alt="" className="w-[35px] h-[18px] pr-[10px]" />
    ),
  },
  {
    key: "en",
    label: "English",
    icon: (
      <img
        src={unitedKingdomFlag}
        alt=""
        className="w-[35px] h-[18px] pr-[10px]"
      />
    ),
  },
];

const LanguageDropdown = () => {
  const { i18n } = useTranslation();

  const onClick = ({ key }) => {
    i18n.changeLanguage(key);
    localStorage.setItem("language", key);
  };

  const currentLanguage = localStorage.getItem("language");

  return (
    <Dropdown
      className="mr-4"
      menu={{
        items,
        onClick,
      }}
      trigger={["click"]}
    >
      {currentLanguage === "en" ? (
        <img
          src={unitedKingdomFlag}
          alt="English"
          className="w-[25px] h-[18px] cursor-pointer"
        />
      ) : (
        <img
          src={vietnamFlag}
          alt="Vietnamese"
          className="w-[25px] h-[18px] cursor-pointer"
        />
      )}
    </Dropdown>
  );
};
export default LanguageDropdown;
