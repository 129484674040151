// Contact.js
import React, { useState } from "react";
import HotelInfo from "../components/HotelInfo";
import SearchPage from "./SearchPage";
import { Button } from "antd";

export default function ManageHotel({ className }) {
  const [page, setPage] = useState(0);
  const [keyword, setKeyword] = useState("");
  const [hotelUrl, setHotelUrl] = useState("");
  function search(e) {
    if (e.keyCode === 13) {
      var elem = e.srcElement || e.target;
      setPage(0);
      setKeyword(elem.value);
    }
  }

  function onSelectHotel(rewrite_url) {
    setPage(1);
    setHotelUrl(rewrite_url);
  }

  return (
    <div className={className}>
      <div className="flex">
        <input
          type="search"
          placeholder="Tìm theo tên khách sạn"
          onKeyDown={search}
          className="border py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline text-sm rounded-lg h-10 w-64"
        ></input>
        <Button
          type="primary"
          ghost
          className="ml-16 h-10"
          onClick={() => {
            setHotelUrl("");
            setKeyword("");
            setPage(1);
          }}
        >
          Thêm khách sạn
        </Button>
      </div>
      <div className="mt-10 w-full flex-1">
        {page === 0 && (
          <SearchPage keyword={keyword} onSelectHotel={onSelectHotel} />
        )}
        {page !== 0 && <HotelInfo hotelUrl={hotelUrl} />}
      </div>
    </div>
  );
}
