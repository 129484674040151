import React from "react";

export default function VieButton({ title, className, onClick }) {
  var classes = `${className} hover:bg-[#CF4317] bg-orange`;
  return (
    <BaseButton
      title={title}
      className={classes}
      onClick={() => {
        onClick();
      }}
    />
  );
}

export function BaseButton({ title, className, onClick }) {
  var classes = `${className} text-base text-white w-full h-[40px] hover:cursor-pointer justify-center rounded-md shadow-md`;
  return (
    <button
      className={classes}
      onClick={() => {
        onClick();
      }}
    >
      {title}
    </button>
  );
}
