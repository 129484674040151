import React from "react";
import BookingItemInfo from "../../components/booking/BookingItemInfo";
import { useTranslation } from "react-i18next";
import PaymentInfo from "./PaymentInfo";

export default function UserBookingFullPaymentInfo({ bookingInfo, onUpdated }) {
  const { t } = useTranslation();

  return (
    <div className="container mx-auto max-w-[1024px] p-8">
      <div className="relative">
        <h2 className="text-lg font-bold">
          {t("bookingInfo.deposit_complete")}
        </h2>

        <div className="lg:hidden md:hidden mt-4">
          {/* Card view for small screens */}
          <BookingItemInfo
            bookingInfo={bookingInfo}
            isAdmin={false}
            isSimpleUI={false}
            onSelect={() => {}}
          />
        </div>
      </div>
      <div className="flex w-full space-x-4 mt-4">
        <div className="w-full">
          <div className="p-3 rounded-xl border-[1px] border-[#039200] bg-[#EFFFEF] mb-6 relative w-full">
            <p>{t("thank_you_deposit")}</p>
            <p className="text-xl text-black font-bold w-fit mx-auto">
              {bookingInfo.reservation_code}
            </p>
          </div>

          <PaymentInfo
            bookingInfo={bookingInfo}
            isCollapsible={true}
            onUpdated={() => {
              onUpdated();
            }}
          />
        </div>
        <div className="hidden md:block lg:block">
          <BookingItemInfo
            bookingInfo={bookingInfo}
            isAdmin={false}
            isSimpleUI={false}
            onSelect={() => {}}
          />
        </div>
      </div>
    </div>
  );
}
