import React from "react";
import { PlusOutlined } from "@ant-design/icons";
import { Form, Divider, Button, Select, InputNumber, DatePicker } from "antd";

const { RangePicker } = DatePicker;

const RoomPrice = ({ field }) => {
  return (
    <Form.List name={[field.name, "prices"]}>
      {(fields, { add, remove }) => {
        return (
          <div>
            {fields.map((item, index) => (
              <div
                key={item.key}
                className="flex flex-col bg-white rounded-lg px-4"
              >
                <Divider>Giá {index + 1}</Divider>
                <div className="justify-end flex">
                  <Button
                    danger
                    ghost
                    className="w-35 h-8 mb-4"
                    onClick={() => remove(item.name)}
                  >
                    Xóa
                  </Button>
                </div>
                <Form.Item
                  name={[index, "type"]}
                  label="Loại giá"
                  rules={[{ required: true }]}
                >
                  <Select>
                    <Select.Option value="0">Giá ngày thường</Select.Option>
                    <Select.Option value="1">Giá ngày lễ</Select.Option>
                    <Select.Option value="2">Giá cuối tuần</Select.Option>
                    <Select.Option value="3">Giá theo mùa</Select.Option>
                  </Select>
                </Form.Item>
                <Form.Item noStyle shouldUpdate>
                  {({ getFieldValue }) => {
                    return getFieldValue([
                      "rooms",
                      field.name,
                      "prices",
                      index,
                      "type",
                    ]) === "3" ? (
                      <Form.Item
                        name={[index, "date_range_local"]}
                        label="Ngày"
                      >
                        <RangePicker format="DD-MM-YYYY" />
                      </Form.Item>
                    ) : null;
                  }}
                </Form.Item>
                <Form.Item
                  name={[index, "original_price_vnd"]}
                  label="Giá niêm yết"
                  rules={[{ required: true }]}
                >
                  <InputNumber
                    className="w-[200px]"
                    formatter={(value) =>
                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }
                  />
                </Form.Item>
                <Form.Item
                  name={[index, "price_vnd"]}
                  label="Giá lữ hành"
                  rules={[{ required: true }]}
                >
                  <InputNumber
                    className="w-[200px]"
                    formatter={(value) =>
                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }
                  />
                </Form.Item>
              </div>
            ))}

            <div className="flex justify-end mt-4">
              <Button type="dashed" onClick={() => add()}>
                <PlusOutlined /> Thêm Giá
              </Button>
            </div>
          </div>
        );
      }}
    </Form.List>
  );
};

export default RoomPrice;
