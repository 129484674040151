import React from "react";
import { PlusOutlined } from "@ant-design/icons";
import { Form, Divider, Button, Input, InputNumber, AutoComplete } from "antd";
import RoomPrice from "./RoomPrice";

import { hotelFullNames } from "../data/constants";

const HotelRoom = () => {
  const form = Form.useFormInstance();

  const hotelNamesEn = hotelFullNames.map((item) => {
    return { value: item.en };
  });

  const hotelNamesVi = hotelFullNames.map((item) => {
    return { value: item.vi };
  });

  const onEnSelect = (value, option, index) => {
    var nameVi = hotelFullNames.filter((item) => item.en === value)[0].vi;
    form.setFieldValue(["rooms", index, "name"], nameVi);
  };
  const onViSelect = (value, option, index) => {
    var nameEn = hotelFullNames.filter((item) => item.vi === value);
    console.log(value, nameEn);
    var name = nameEn[0] ? nameEn[0].en : "";
    form.setFieldValue(["rooms", index, "name_en"], name);
  };

  return (
    <Form.List name="rooms">
      {(fields, { add, remove }) => {
        return (
          <>
            {fields.map((field, index) => (
              <div className="ml-4" key={index}>
                <Divider>Phòng {index + 1}</Divider>
                <div className="justify-end flex">
                  <Button
                    danger
                    ghost
                    className="w-35 h-8 mb-4"
                    onClick={() => remove(field.name)}
                  >
                    Xóa
                  </Button>
                </div>
                <Form.Item
                  labelCol={{ span: 3 }}
                  name={[index, "name_en"]}
                  label="Name_En"
                  rules={[{ required: true }]}
                >
                  <AutoComplete
                    options={hotelNamesEn}
                    placeholder="Name in English"
                    onSelect={(value, option) => {
                      onEnSelect(value, option, index);
                    }}
                    filterOption={(inputValue, option) =>
                      option.value
                        .toUpperCase()
                        .indexOf(inputValue.toUpperCase()) !== -1
                    }
                  />
                </Form.Item>
                <Form.Item
                  name={[index, "name"]}
                  label="Name_Vi"
                  labelCol={{ span: 3 }}
                  rules={[{ required: true }]}
                >
                  <AutoComplete
                    options={hotelNamesVi}
                    onSelect={(value, option) => {
                      onViSelect(value, option, index);
                    }}
                    placeholder="Tên khách sạn"
                    filterOption={(inputValue, option) =>
                      option.value
                        .toUpperCase()
                        .indexOf(inputValue.toUpperCase()) !== -1
                    }
                  />
                </Form.Item>
                <Form.Item
                  name={[index, "max_people"]}
                  label="Số khách"
                  labelCol={{ span: 3 }}
                  rules={[{ required: true }]}
                >
                  <InputNumber className="w-[120px]" />
                </Form.Item>
                <Form.Item
                  name={[index, "extra_data"]}
                  label="Link ảnh"
                  labelCol={{ span: 3 }}
                >
                  <Input />
                </Form.Item>
                <div className="ml-16 ">
                  <RoomPrice field={field} />
                </div>
              </div>
            ))}
            <Divider />
            <div className="flex justify-center">
              <Button className="mx-auto" type="dashed" onClick={() => add()}>
                <PlusOutlined /> Thêm phòng
              </Button>
            </div>
          </>
        );
      }}
    </Form.List>
  );
};

export default HotelRoom;
