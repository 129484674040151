import React from "react";

const Page404 = () => {
  return (
    <main className="min-h-screen flex items-center justify-center bg-appBg">
      <div className="text-center">
        <h1 className="text-6xl font-bold text-gray-800">404</h1>
        <h2 className="text-2xl font-semibold text-gray-700">Page not found</h2>
        <p className="text-gray-600 mt-4">
          Sorry, we couldn't find the page you're looking for.
        </p>
        <a href="/" className="text-indigo-600 mt-8 inline-block underline">
          Go back home
        </a>
      </div>
    </main>
  );
};

export default Page404;
