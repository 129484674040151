import React from "react";
import BookingItemInfo from "../../components/booking/BookingItemInfo";
import { useTranslation } from "react-i18next";
import PaymentInfo from "./PaymentInfo";

export default function UserBookingDepositPaymentInfo({
  bookingInfo,
  onUpdated,
}) {
  const { t } = useTranslation();
  return (
    <div className="container mx-auto max-w-[1024px] p-8">
      <div className="relative">
        <div className="lg:hidden md:hidden mt-4">
          {/* Card view for small screens */}
          <BookingItemInfo
            bookingInfo={bookingInfo}
            isAdmin={false}
            isSimpleUI={false}
            onSelect={() => {}}
          />
        </div>
      </div>
      <div className="flex w-full space-x-4 mt-4">
        <PaymentInfo bookingInfo={bookingInfo} onUpdated={onUpdated} />
        <div className="hidden md:block lg:block">
          <BookingItemInfo
            bookingInfo={bookingInfo}
            isAdmin={false}
            isSimpleUI={false}
            onSelect={() => {}}
          />
        </div>
      </div>
    </div>
  );
}
