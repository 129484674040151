import React from "react";
import { formatPrice } from "../../features/utils";

export function RoomPriceByHotel({ room }) {
  return (
    <>
      <p className="text-base font-bold text-[#003AA9]">{room.name}</p>
      <p className="text-sm mt-2 text-textSecondary">
        Khách sạn báo giá: {formatPrice(room.unit_price_vnd, "vi")}
      </p>
    </>
  );
}

export default function RoomInfoByAdmin({ bookingInfo }) {
  var isPaid = bookingInfo.status !== "pending_payment";
  var hotelConfirmCode = bookingInfo.confirm_code;
  return (
    <div className=" bg-white rounded-lg p-4 w-full">
      {bookingInfo.rooms.map((room, index) => (
        <RoomPriceByHotel room={room} />
      ))}
      {isPaid && (
        <p className="text-sm mt-2 text-black">
          Đã cọc: {formatPrice(bookingInfo.deposit_value_vnd, "vi")}
        </p>
      )}
      {isPaid && (
        <p className="text-sm mt-2 text-black">
          Phương thức: {bookingInfo.deposit_method}
        </p>
      )}
      {hotelConfirmCode && (
        <p className="text-sm mt-2 text-black">
          Mã nhận phòng: {hotelConfirmCode}
        </p>
      )}
    </div>
  );
}

export function ViewOnlyField({ className, text }) {
  var classes = `pt-[10px] rounded-md border-[1px] border-[#D9D9D9] pl-[10px] bg-[#E6E6E6] ${className}`;
  return <p className={classes}>{text}</p>;
}

export function InfoText({ className, text }) {
  var classes = `p-3 rounded-xl border-[1px] border-[#039200] bg-[#EFFFEF] ${className} text-base text-black`;
  return <p className={classes}>{text}</p>;
}
