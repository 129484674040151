export const hotelFullNames = [
  {
    code: "family",
    en: "Family Room",
    vi: "Phòng gia đình",
  },
  {
    code: "tripple",
    en: "Tripple Room",
    vi: "Phòng 3 người",
  },
  {
    code: "dorm",
    en: "Dormitory Room",
    vi: "Phòng tập thể",
  },
  {
    code: "stan_t",
    en: "Standard Twin Room",
    vi: "Phòng tiêu chuẩn 2 giuờng đơn",
  },
  {
    code: "supe_t",
    en: "Superior Twin Room",
    vi: "Phòng cao cấp 2 giuờng đơn",
  },
  {
    code: "delu_t",
    en: "Deluxe Twin Room",
    vi: "Phòng sang trọng 2 giuờng đơn",
  },
  {
    code: "exec_t",
    en: "Executive Twin Room",
    vi: "Phòng execute 2 giuờng đơn",
  },
  {
    code: "stan_d",
    en: "Standard Double Room",
    vi: "Phòng tiêu chuẩn giường đôi",
  },
  {
    code: "supe_d",
    en: "Superior Double Room",
    vi: "Phòng cao cấp giường đôi",
  },
  {
    code: "delu_d",
    en: "Deluxe Double Room",
    vi: "Phòng sang trọng giường đôi",
  },
  {
    code: "exec_d",
    en: "Executive Double Room",
    vi: "Phòng execute giường đôi",
  },
  { code: "suit", en: "Suite Room", vi: "Phòng suite" },
];

export const roomViews = [
  {
    code: "none",
    en: "",
    vi: "",
  },
  {
    code: "pool",
    en: "Pool View",
    vi: "View hồ bơi",
  },
  {
    code: "sea",
    en: "Sea View",
    vi: "View biển",
  },
  {
    code: "garden",
    en: "Garden View",
    vi: "View vườn",
  },
  {
    code: "mountain",
    en: "Mountain View",
    vi: "View núi",
  },
  {
    code: "city",
    en: "City View",
    vi: "View thành phố",
  },
  {
    code: "lake",
    en: "Lake View",
    vi: "View hồ",
  },
  {
    code: "internal",
    en: "Internal window",
    vi: "View nội khu",
  },
];

export function getAgodaUrl(hotelName) {
  return (
    "https://www.agoda.com/pages/agoda/default/page_textSearchResult.aspx?productType=-1&travellerType=1&familyMode=off&textToSearch=" +
    hotelName
  );
}

export function getBookingUrl(hotelName) {
  return "https://www.booking.com/searchresults.html?ss=replace_me&ssne=Vietnam&ssne_untouched=Vietnam&dest_type=hotel".replace(
    "replace_me",
    hotelName
  );
}

export const dateFormat = "DD-MM-YYYY";

export const serverDisplayDateFormat = "DD-MM-YYYY  hh:mm:ss";

export const displayDateFormat = "DD MMM YYYY";

export const bankQR =
  "https://img.vietqr.io/image/tcb-19032742021017-compact.jpg?amount=amount_no&addInfo=blahblah&accountName=Nguyen%20Van%20Tien";

export const BookingStatus = {
  canceled: "canceled",
  done: "done",
  pending_confirmation: "pending_confirmation",
  pending_payment: "pending_payment",
  user_confirm_deposit: "user_confirm_deposit",
  sale_confirm_deposit: "sale_confirm_deposit",
  user_confirm_payment: "user_confirm_payment",
};
