// App.js hoặc index.js

import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n"; // Import i18n configuration
import Header from "./components/Header";
import HotelDetail from "./pages/HotelDetail";
import Home from "./pages/Home";
import About from "./pages/About";
import Page404 from "./pages/Page404";
import UserBookingInfo from "./pages/userbooking/UserBookingInfo.js";
import AdminPage from "./pages/Admin.js";
import LoginPage from "./pages/Login.js";
import Sitemap from "./pages/Sitemap.js";
import UserBookingConfirmInfo from "./pages/userbooking/UserBookingConfirmInfo.js";

const App = () => {
  return (
    <I18nextProvider i18n={i18n}>
      <Router>
        <Routes>
          <Route path="/sitemap.xml" element={<Sitemap />} />
          <Route
            path="/"
            element={
              <div className="h-lvh bg-appBg overflow-auto" id="scrollableDiv">
                <Header className="bg-customYellow" />
                <Home />
                <div></div>
              </div>
            }
          />
          <Route
            path="/area/:area"
            element={
              <div className="h-lvh bg-appBg">
                <Header className="bg-customYellow" />
                <Home />
                <div></div>
              </div>
            }
          />

          <Route
            path="/about"
            element={
              <div>
                <Header className="bg-about1st container mx-auto" />
                <About />
              </div>
            }
          />

          <Route
            path="/hotel/:rewrite_url"
            element={
              <div className="bg-appBg">
                <Header className="bg-customYellow" />
                <HotelDetail />
              </div>
            }
          />
          <Route
            path="/bookinginfo"
            element={
              <div className="h-lvh bg-appBg overflow-y-auto">
                <Header className="bg-customYellow" />
                <UserBookingInfo />
              </div>
            }
          />
          <Route
            path="/bookingConfirm/:code"
            element={
              <div className="bg-appBg h-lvh overflow-y-auto">
                <Header className="bg-customYellow" />
                <UserBookingConfirmInfo />
              </div>
            }
          />
          <Route
            path="*"
            element={
              <div className="bg-appBg">
                <Header className="bg-customYellow" />
                <Page404 />
              </div>
            }
          />
          <Route
            path="/admin/:page"
            element={
              <div className="">
                <AdminPage />
              </div>
            }
          />
          <Route
            path="/admin/login"
            element={
              <div className="">
                <LoginPage />
              </div>
            }
          />
        </Routes>
        {/* Replace YOUR_PAGE_ID with your actual Facebook Page ID */}
      </Router>
    </I18nextProvider>
  );
};

export default App;
