import firebase from "firebase/compat/app";
import "firebase/compat/analytics";

const uuid = localStorage.getItem("uuid") ?? generateUUID();

function generateUUID() {
  let d = new Date().getTime(),
    d2 =
      (typeof performance !== "undefined" &&
        performance.now &&
        performance.now() * 1000) ||
      0;
  let uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
    let r = Math.random() * 16;
    if (d > 0) {
      r = (d + r) % 16 | 0;
      d = Math.floor(d / 16);
    } else {
      r = (d2 + r) % 16 | 0;
      d2 = Math.floor(d2 / 16);
    }
    return (c === "x" ? r : (r & 0x7) | 0x8).toString(16);
  });
  localStorage.setItem("uuid", uuid);
  return uuid;
}

const firebaseConfig = {
  apiKey: "AIzaSyCKxjEf_Iw4X0SWOBABKyHmg-HV-e0LC8g",
  authDomain: "viestay-dts-2024.firebaseapp.com",
  projectId: "viestay-dts-2024",
  storageBucket: "viestay-dts-2024.appspot.com",
  messagingSenderId: "281525729232",
  appId: "1:281525729232:web:ae9c41f38f7f57ecce6bd5",
  measurementId: "G-7MTVG1MPJB",
};

const firebaseApp = firebase.initializeApp(firebaseConfig);
const analytics = firebase.analytics();
analytics.setUserId(uuid);
console.log(uuid);

export { firebaseApp, analytics };
