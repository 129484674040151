import React from "react";
import RoomInfoByAdmin from "./BookingComponents";

export default function AdminBookingNoAction({ bookingInfo }) {
  return (
    <div className="w-[350px]">
      <RoomInfoByAdmin bookingInfo={bookingInfo} />
    </div>
  );
}
