import React from "react";
import { Link } from "react-router-dom";
import { ReactComponent as Logo } from "../assets/logo.svg";
import { useTranslation } from "react-i18next";
import LanguageDropdown from "./LanguageDropDown";

const Header = ({ className }) => {
  const { t } = useTranslation();
  const classes = `lg:px-6 md:px-4 ${className}`;
  return (
    <div style={{ position: "relative" }}>
      <header className={classes}>
        <div className="w-full flex justify-between items-center px-4 py-2 md:py-3">
          {/* Logo */}
          <div className="flex items-center">
            <Link to="/">
              <Logo className="h-8 lg:h-12 w-auto" />
            </Link>
          </div>
          {/* Navigation Pages */}
          <div className="flex items-center">
            <LanguageDropdown className="m-4" /> {/* Language dropdown */}
            <Link to="/About" className="text-base underline text-textCommon">
              {t("Header-TabAbout")}
            </Link>
          </div>
        </div>
      </header>
    </div>
  );
};

export default Header;
