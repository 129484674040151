import { InputNumber } from "antd";
import React, { useRef } from "react";

export default function ComfirmPriceComponent({ room, onChange }) {
  var priceFinalRef = useRef();

  const handleTextChange = (value) => {
    var priceFinal = priceFinalRef.current;
    var unitPrice = Number(value) * 1.1;
    room.unit_price_vnd = Number(value);
    priceFinal.innerHTML = `${unitPrice.toLocaleString()} VND`;
    onChange(room);
  };

  return (
    <div>
      <p className="text-base font-bold text-[#003AA9] mt-2">{room.name}</p>
      <p className="text-sm mt-2 text-textSecondary">Khách sạn báo giá</p>
      <InputNumber
        id="price_origin"
        type="number"
        onChange={handleTextChange}
        className="w-full bg-gray-50 border h-[45px] border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 mt-2 pt-[7px]"
      />
      <p className="text-sm mt-2 text-textSecondary">Giá khách trả</p>
      <p
        id="price_final"
        ref={priceFinalRef}
        className="pt-[10px] w-[350px] h-[45px] rounded-md border-[1px] border-[#D9D9D9] pl-[10px] bg-[#E6E6E6] mt-2"
      />
    </div>
  );
}
