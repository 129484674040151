import React from "react";
import { getTravelokaTodayUrl } from "../features/utils";
import { useTranslation } from "react-i18next";

const PriceCheckHorizontal = ({ hotel }) => {
  const { booking_url, traveloka_url, agoda_url } = hotel;
  const { t } = useTranslation();
  const openWebsite = (url) => {
    if (url) {
      window.open(url, "_blank");
    }
  };

  return (
    <div className="bg-highlight rounded-3xl w-min mx-auto px-4 py-4 h-min mt-4 mb-4 flex flex-col lg:hidden text-xs md:text-sm">
      <p className="text-wrap md:w-[400px] text-center text-sm">
        {t("price_check_guide")}
      </p>
      <div className="flex flex-row items-start w-min mx-auto">
        {traveloka_url && (
          <button
            className="rounded-2xl w-[100px] md:w-[130px] h-[30px] md:h-[40px] bg-grey mt-2"
            onClick={() => openWebsite(getTravelokaTodayUrl(traveloka_url))}
          >
            Traveloka
          </button>
        )}
        {booking_url && (
          <button
            className="rounded-2xl w-[100px] md:w-[130px] h-[30px] md:h-[40px] bg-grey mt-2 ml-2"
            onClick={() => openWebsite(booking_url)}
          >
            Booking.com
          </button>
        )}
        {agoda_url && (
          <button
            className="rounded-2xl w-[100px] md:w-[130px] h-[30px] md:h-[40px] bg-grey mt-2 ml-2"
            onClick={() => openWebsite(agoda_url)}
          >
            Agoda
          </button>
        )}
      </div>
    </div>
  );
};

export default PriceCheckHorizontal;
