import React from "react";
import GetDisplayPrice from "../features/utils";
import { ReactComponent as People } from "../assets/people.svg";
import { useTranslation } from "react-i18next";

export function UserBookingRoom({ selectedRoom, bookingInfo }) {
  const { t, i18n } = useTranslation();
  return (
    <>
      <h2 className="font-bold my-4 text-blue-700">
        <span>{bookingInfo[selectedRoom.id]}x</span>
        {i18n.language === "vi" ? selectedRoom.name : selectedRoom.name_en}
      </h2>
      <div className="mb-2 flex items-center">
        <People />{" "}
        {selectedRoom.max_people !== null && selectedRoom.max_people > 0 ? (
          <span className="ml-2 text-blue-700">
            {selectedRoom.max_people} {t("Booking-NumberGuest")}
          </span>
        ) : (
          <span className="ml-2 text-blue-700">
            {t("Booking-NoMaxCapacity")}
          </span>
        )}
      </div>

      {selectedRoom.prices.map((price, index) => (
        <p key={index} className="mb-2">
          <GetDisplayPrice price={price} language={i18n.language} />
        </p>
      ))}
    </>
  );
}

export function UserConfirmRoom({ selectedRoom }) {
  const { t, i18n } = useTranslation();
  return (
    <>
      <h2 className="font-bold my-4 text-blue-700">
        <span>{selectedRoom.quantity}x</span>
        {i18n.language === "vi" ? selectedRoom.name : selectedRoom.name_en}
      </h2>
      <div className="mb-2 flex items-center">
        <People />{" "}
        {selectedRoom.max_people !== null && selectedRoom.max_people > 0 ? (
          <span className="ml-2 text-blue-700">
            {selectedRoom.max_people} {t("Booking-NumberGuest")}
          </span>
        ) : (
          <span className="ml-2 text-blue-700">
            {t("Booking-NoMaxCapacity")}
          </span>
        )}
      </div>

      {selectedRoom.prices.map((price, index) => (
        <p key={index} className="mb-2">
          <GetDisplayPrice price={price} language={i18n.language} />
        </p>
      ))}
    </>
  );
}
