// HotelDetailSlice.js
import { createSlice } from "@reduxjs/toolkit";
import { getHotelByUrl } from "../../services/api";

const initialState = {
  hotel: null,
  loading: false,
  error: null,
};

const hotelDetailSlice = createSlice({
  name: "hotelDetail",
  initialState,
  reducers: {
    fetchHotelStart(state) {
      state.loading = true;
      state.error = null;
    },
    fetchHotelSuccess(state, action) {
      state.loading = false;
      state.hotel = action.payload;
    },
    fetchHotelFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { fetchHotelStart, fetchHotelSuccess, fetchHotelFailure } =
  hotelDetailSlice.actions;

export const fetchHotelData = (rewrite_url) => async (dispatch) => {
  try {
    dispatch(fetchHotelStart());
    const responseData = await getHotelByUrl(rewrite_url);
    dispatch(fetchHotelSuccess(responseData));
  } catch (error) {
    console.error("Error fetching hotel data:", error);
    dispatch(fetchHotelFailure(error.message));
  }
};

export default hotelDetailSlice.reducer;
