import React from "react";
import { ReactComponent as People } from "../assets/people.svg";
import GetDisplayPrice from "../features/utils";
import { useTranslation } from "react-i18next";
import NumberInput from "../components/NumberInput.js";

export default function BookingRoom({
  room,
  key,
  onNumberChange,
  onRequestBooking,
}) {
  const { t, i18n } = useTranslation();

  return (
    <div
      key={key}
      className="bg-white rounded-lg p-4 relative text-sm md:text-base"
    >
      <h2 className="font-bold mb-2 text-blue-700">
        {i18n.language === "vi" ? room.name : room.name_en}
      </h2>
      <div className="mb-2 flex items-center">
        <People />{" "}
        {room.max_people !== null && room.max_people > 0 ? (
          <span className="ml-2 text-blue-700">
            {room.max_people} {t("Booking-NumberGuest")}
          </span>
        ) : (
          <span className="ml-2 text-blue-700">
            {t("Booking-NoMaxCapacity")}
          </span>
        )}
      </div>
      {room.prices.map((price, key) => (
        <p key={key} className="mb-2">
          <GetDisplayPrice price={price} language={i18n.language} />
        </p>
      ))}
      <div className="flex flex-row items-center w-full justify-end">
        <div className="mr-4">
          <NumberInput onChange={onNumberChange} />
        </div>

        <div
          onClick={() => {
            onRequestBooking();
          }}
          className="bg-orange text-white px-4 rounded-md text-sm hover:cursor-pointer relative py-2"
        >
          {t("Request-Booking")}
        </div>
      </div>
    </div>
  );
}
