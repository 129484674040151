import React, { useState } from "react";

const NumberInput = ({ initialValue = 0, onChange }) => {
  const [count, setCount] = useState(initialValue);

  function increment() {
    var newCount = Math.max(count + 1, 0);
    setCount(newCount); // Enforce minimum of 0
    onChange(newCount);
  }

  function decrement() {
    var newCount = Math.max(count - 1, 0);
    setCount(newCount); // Enforce minimum of 0
    onChange(newCount);
  }

  const handleChange = (event) => {
    const newValue = parseInt(event.target.value, 10);
    if (!isNaN(newValue)) {
      setCount(newValue);
    }
  };

  return (
    <div className="py-2 px-3 inline-block bg-white border border-gray-200 rounded-lg">
      <div className="flex items-center gap-x-1.5">
        <button
          type="button"
          className="size-6 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-md border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none"
          onClick={decrement}
        >
          <svg
            className="flex-shrink-0 size-3.5"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
          >
            <path d="M5 12h14"></path>
          </svg>
        </button>
        <input
          className="p-0 w-6 bg-transparent border-0 text-gray-800 text-center focus:ring-0"
          type="text"
          value={count}
          onChange={handleChange}
        />
        <button
          type="button"
          className="size-6 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-md border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none"
          onClick={increment}
        >
          <svg
            className="flex-shrink-0 size-3.5"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
          >
            <path d="M5 12h14"></path>
            <path d="M12 5v14"></path>
          </svg>
        </button>
      </div>
    </div>
  );
};

export default NumberInput;
