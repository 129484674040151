import React, { useEffect, useState } from "react";
import { message } from "antd";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import LoadingIndicator from "../../components/Loading";
import { getRequest } from "../../services/api";
import UserBookingPendingInfo from "./UserBookingPendingInfo";
import { BookingStatus } from "../../data/constants";
import UserBookingDepositPaymentInfo from "./UserBookingDepositPaymentInfo";
import UserBookingFullPaymentInfo from "./UserBookingFullPaymentInfo";
import UserBookingDone from "./UserBookingDone";
import UserBookingUserConfirmInfo from "./UserBookingUserConfirmInfo";

export default function UserBookingConfirmInfo() {
  let { code } = useParams();
  const { t } = useTranslation();

  var [bookingInfo, setBookingInfo] = useState(null);
  var [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    loadBookingInfo();
  }, [code]);

  function loadBookingInfo() {
    getRequest("/booking/get?code=" + code)
      .then((response) => {
        setIsLoading(false);
        var booking = response.data.booking;
        setBookingInfo(booking);
      })
      .catch((error) => {
        setIsLoading(false);
        message.open({ type: "error", content: t("something_went_wrong") });
        console.log(error);
      });
  }

  return (
    <div className="relative">
      {isLoading && <LoadingIndicator />}
      {bookingInfo?.status === BookingStatus.pending_confirmation && (
        <UserBookingPendingInfo bookingInfo={bookingInfo} />
      )}
      {bookingInfo?.status === BookingStatus.pending_payment && (
        <UserBookingDepositPaymentInfo
          bookingInfo={bookingInfo}
          onUpdated={() => {
            loadBookingInfo();
          }}
        />
      )}
      {bookingInfo?.status === BookingStatus.user_confirm_deposit && (
        <UserBookingUserConfirmInfo bookingInfo={bookingInfo} />
      )}
      {bookingInfo?.status === BookingStatus.sale_confirm_deposit && (
        <UserBookingFullPaymentInfo
          bookingInfo={bookingInfo}
          onUpdated={() => {
            loadBookingInfo();
          }}
        />
      )}
      {(bookingInfo?.status === BookingStatus.done ||
        bookingInfo?.status === BookingStatus.user_confirm_payment) && (
        <UserBookingDone bookingInfo={bookingInfo} />
      )}
    </div>
  );
}
