import { FloatButton, QRCode } from "antd";
import React from "react";
import { FiMessageCircle } from "react-icons/fi";
import { RiMessengerLine } from "react-icons/ri";
import { PiTelegramLogo } from "react-icons/pi";
import { FaWhatsapp } from "react-icons/fa6";
import { ReactComponent as IconZalo } from "../assets/ic_zalo.svg";
import { t } from "i18next";

export default function FloatContact() {
  const phone = "84973480166";
  const whatAppValue = `whatsapp://send?phone=+${phone}`;
  const telegram = `tg://resolve?domain=viestay_vn`;
  const zalo = `https://zalo.me/${phone}`;
  const messenger = "https://m.me/61559224967813";

  function QRSegment({ name, url }) {
    return (
      <div className="flex flex-col bg-white text-primary">
        <QRCode value={url} className="w-[120px] h-[120px] mx-auto" />
        <p className="text-sm mt-2 mx-auto">{t("scan_to_chat")}</p>
        <p className="mx-auto">{name}</p>
      </div>
    );
  }

  return (
    <>
      <FloatButton.Group
        trigger="click"
        style={{
          right: 24,
        }}
        className="mx-auto"
        icon={<FiMessageCircle />}
      >
        <FloatButton
          className="mx-auto"
          tooltip={<QRSegment name="Whatapp" url={whatAppValue} />}
          onClick={() => {
            window.open(whatAppValue, "_blank");
          }}
          icon={<FaWhatsapp />}
        />
        <FloatButton
          icon={<PiTelegramLogo />}
          tooltip={<QRSegment name="Telegram" url={telegram} />}
          onClick={() => {
            window.open(telegram, "_blank");
          }}
        />
        <FloatButton
          icon={React.createElement(IconZalo)}
          tooltip={<QRSegment name="Zalo" url={zalo} />}
          onClick={() => {
            window.open(zalo, "_blank");
          }}
        />
        <FloatButton
          icon={<RiMessengerLine />}
          tooltip={<QRSegment name="Messenger" url={messenger} />}
          onClick={() => {
            window.open(messenger, "_blank");
          }}
        />
      </FloatButton.Group>
    </>
  );
}
