import React, { useState, useEffect } from "react";
import homeBanner from "../assets/banner_img-min.jpg";
import { ReactComponent as IconSearch } from "../assets/ic_search.svg";
import { useTranslation } from "react-i18next";
import { useParams, useSearchParams } from "react-router-dom";
import Image from "../components/Image";
import { getRequest, searchHotels } from "../services/api";
import Skeleton from "../components/Skeleton";
import FloatContact from "../components/Contact";
import { formatPrice } from "../features/utils";
import { Helmet } from "react-helmet";
import { analytics } from "../firebase/firebase";
import { Input } from "antd";
import InfiniteScroll from "react-infinite-scroll-component";

export default function Home() {
  const { t, i18n } = useTranslation();
  const [recommendHotels, setRecommendHotels] = useState([]);
  const [loading, setLoading] = useState(true);  
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const [param] = useSearchParams();
  const [searchKeyword, setSearchKeyword] = useState(param.get("q"));

  let { area } = useParams();

  if (area && area.includes("_")) {
    area = area.replace("_", " ");
  }

  const fetchHotels = async (area, searchKeyword, recomrecommended) => {
    try {
      var hotels = [];
      if (area) {
        let response = await getRequest(`/hotel/area?area=${area}`);
        hotels = response.data.hotels;
        setHasMore(false);
      } else {
        hotels = await searchHotels(
          searchKeyword,
          null,
          recomrecommended,
          page
        );
        setHasMore(hotels.length === 50);
        if (page > 1) {
          hotels = recommendHotels.concat(hotels);
        }
        setPage(page + 1);
      }
      setRecommendHotels(hotels);
      if (searchKeyword) {
        analytics.logEvent("search_result", {
          keyword: searchKeyword,
          result_count: hotels.length,
        });
      }
      // Update the state with fetched hotels
      setLoading(false);
    } catch (error) {
      console.error("Error fetching hotels:", error);
      setLoading(false);
    }
  };

  const mainImage =
    recommendHotels.length > 0 ? recommendHotels[0].first_image : "";

  useEffect(() => {
    fetchData();
  }, [searchKeyword]);

  function fetchData() {
    if (searchKeyword) {
      fetchHotels(null, searchKeyword);
    } else if (!searchKeyword) {
      if (area) {
        fetchHotels(area, null, false);
      } else {
        fetchHotels(null, null, true);
      }
    }
  }

  const handleSearchInputChange = (e) => {
    setPage(1);
    setSearchKeyword(e.target.value);
  };

  function navigateHotelDetail(hotel) {
    const url = `/hotel/${hotel.rewrite_url}`;
    window.open(url, "_self");
  }

  function clickRecommendedHotel(hotel) {
    analytics.logEvent("click", {
      source: "home_recommended",
    });
    navigateHotelDetail(hotel);
  }

  const description = area
    ? `${t("Title-recommended-area")} ${area.toUpperCase()}`
    : t("Title-recommended");

  const title = t("meta.title.home");

  return (
    <div className="w-full bg-appBg h-fit">
      <Helmet>
        <title property="og:title">{title}</title>
        <meta name="title" property="og:title" content={title} />
        <meta
          name="description"
          property="og:description"
          content={description}
        />
        <meta property="og:image" content={mainImage} />
      </Helmet>

      <FloatContact />
      {/* Section 1 */}
      <section className="w-full relative">
        <div>
          <div className="w-full bg-customYellow relative lg:h-[312px] md:h-64 h-[150px]">
            {/* Image */}
            <div className="h-full w-[50%] flex flex-col justify-center lg:text-lg	md:text-base text-xs">
              <p className="text-end text-textBanner lg:text-lg	md:text-base text-xs lg:pl-8 md:pl-8 pl-4">
                <span className="text-[#8D8D8D]">{t("Slogan-1")}</span>
                <br />
                {t("Slogan-2")}
                <span className="font-bold">{t("Slogan-3")}</span>
                {t("Slogan-4")}
              </p>
            </div>
            <img
              src={homeBanner}
              alt=""
              className="h-full ml-[55%] absolute top-0"
            />
          </div>
          {/* Search Box */}

          <div className="relative bottom-[30px] max-w-2xl mx-5 md:mx-auto flex flex-row rounded-md shadow-md items-center">
            <Input
              type="text"
              enterKeyHint="search"
              placeholder={t("search_guide")}
              onPressEnter={handleSearchInputChange}      
              defaultValue={searchKeyword}        
              className="w-full py-2 pr-4 border-none focus:outline-none h-14 rounded-md font-nunito pl-12"
            />
            <IconSearch className="fill-gray-400 absolute left-4" />
          </div>
        </div>
      </section>
      {/* Section 2 */}
      <section className="relative mt-11 md:mt-14 mx-auto px-5 container bottom-12">
        <h1 className="text-sm md:text-base mb-3 md:mb-6 mx-1 font-semibold">
          {searchKeyword
            ? t("search_result_title") + `: "${searchKeyword.toUpperCase()}"`
            : area
            ? t("Title-recommended-area") + ` ${area.toUpperCase()}`
            : t("Title-recommended")}
        </h1>
        <InfiniteScroll
          dataLength={recommendHotels.length}
          next={() => {
            console.log("nextt");
            fetchData();
          }}
          hasMore={hasMore}
          loader={
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 h-fit mt-6">
              {Array.from({ length: 4 }).map((_, index) => (
                <Skeleton key={index} className="rounded-lg h-[300px]" />
              ))}
            </div>
          }
          scrollableTarget="scrollableDiv"
        >
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 h-fit">
            {/* Render skeleton if loading, otherwise render hotel cards */}
            {loading
              ? Array.from({ length: 8 }).map((_, index) => (
                  <Skeleton key={index} className="rounded-lg h-[300px]" />
                ))
              : recommendHotels.map((hotel, index) => (
                  <div
                    key={index}
                    onClick={() => {
                      clickRecommendedHotel(hotel);
                    }}
                    className="rounded-lg overflow-hidden bg-white p-1 relative hover:cursor-pointer"
                  >
                    <div className="rounded-lg">
                      <Image
                        src={hotel.first_image}
                        alt={hotel.name}
                        className="aspect-[5/3] w-full object-cover rounded-lg"
                      />
                    </div>
                    <div className="p-2 text-sm md:text-base">
                      <h2 className="font-semibold mb-2">{hotel.name}</h2>
                      <h3 className="text-gray-600 mb-8">
                        {hotel.ward.full_name}, {hotel.district.name},{" "}
                        {hotel.province.name}
                      </h3>
                      <p className="text-gray-600 right-4 bottom-4 text-right absolute">
                        {t("price-from")}{" "}
                        <span className="font-bold">
                          {" "}
                          {formatPrice(
                            hotel.display_best_price_vnd,
                            i18n.language
                          )}
                        </span>
                      </p>
                      {/* Render prices for each hotel */}
                    </div>
                  </div>
                ))}
          </div>
        </InfiniteScroll>
      </section>
    </div>
  );
}
