import React from "react";
import { getTravelokaTodayUrl } from "../features/utils";
import { useTranslation } from "react-i18next";

const PriceCheckVertical = ({ hotel }) => {
  // Destructure the "hotel" prop to access the hotel data
  const { booking_url, traveloka_url, agoda_url } = hotel;
  const { t } = useTranslation();
  return (
    <div className="bg-highlight rounded-3xl w-52 px-4 py-6 h-min mt-16 items-center lg:flex flex-col hidden text-sm">
      <p>{t("price_check_guide")}</p>
      {/* Render buttons conditionally based on the presence of the URLs */}
      {traveloka_url && (
        <button
          className="rounded-2xl w-[130px] h-[40px] bg-grey mx-auto mt-2"
          onClick={() => {
            window.open(getTravelokaTodayUrl(traveloka_url), "_blank");
          }}
        >
          Traveloka
        </button>
      )}
      {booking_url && (
        <button
          className="rounded-2xl w-[130px] h-[40px] bg-grey mx-auto mt-4"
          onClick={() => window.open(booking_url, "_blank")}
        >
          Booking.com
        </button>
      )}
      {agoda_url && (
        <button
          className="rounded-2xl w-[130px] h-[40px] bg-grey mx-auto mt-2"
          onClick={() => window.open(agoda_url, "_blank")}
        >
          Agoda
        </button>
      )}
    </div>
  );
};

export default PriceCheckVertical;
