import React, { useEffect, useState } from "react";
import { getRequest } from "../services/api";
import { Spin } from "antd";
import { FaCheck } from "react-icons/fa";
import { GiCancel } from "react-icons/gi";
import BookingItemInfo from "../components/booking/BookingItemInfo";
import { Pagination } from "antd";

export default function BookingList({ onSelect }) {
  const [bookingList, setBookingList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    getRequest(
      "/booking/list?page_size=100&sort_field=update_time&sort_order=desc"
    )
      .then((response) => {
        setBookingList(response.data.bookings);
        setIsLoading(false);
      })
      .catch((error) => console.log(error));
  }, [false]);

  if (isLoading) {
    return <Spin size="large" className="mx-auto mt-12"></Spin>;
  }
  return (
    <div className="w-full flex flex-col no-scrollbar">
      {/* <div className="flex flex-row m-4">
        <FilterItem name={"Đang xử lý"} />
        <FilterItem name={"Hoàn thành"} />
        <FilterItem name={"Đã hủy"} />
      </div> */}
      {/* <input
        className="w-[400px] h-[45px] rounded-md border-[1px] border-[#D9D9D9] ml-4 pl-4"
        placeholder="Tìm bằng ID, tên khách hàng, khách sạn, sale"
      ></input> */}
      <div className="w-full flex-1 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 p-4 bg-appBg overflow-y-auto">
        {bookingList.map((bookingItem, index) => (
          <BookingItemInfo
            bookingInfo={bookingItem}
            isSimpleUI={true}
            isAdmin={true}
            key={index}
            onSelect={onSelect}
          />
        ))}
      </div>
      {/* <div className="justify-center flex">
        <Pagination
          defaultCurrent={1}
          total={50}
          className="py-2 justify-center items-center"
        />
      </div> */}
    </div>
  );
}

function FilterItem({ name, isSelected, onClick }) {
  if (isSelected) {
    return (
      <div
        className="w-[120px] h-[35px] rounded-lg border-[1px] border-[#687176] mr-3"
        onClick={onClick}
      >
        <p className="text-sm font-bold text-[#687176]  text-center mt-[6px]">
          {name}
        </p>
      </div>
    );
  } else {
    return (
      <div
        className="w-[120px] h-[35px] rounded-lg border-[1px] border-[#F75D2D] bg-[#FFD686] mr-3 relative"
        onClick={onClick}
      >
        <p className="text-sm font-bold text-[#F75D2D] text-center mt-[6px]">
          {name}
        </p>
      </div>
    );
  }
}

function BookingItem({ bookingItem, onSelect }) {
  return (
    <div className="border border-gray-300 rounded-md px-4 pt-4 h-fit">
      <p className="font-semibold text-lg">Booking Code: {bookingItem.code}</p>
      <p className="font-semibold">Name: {bookingItem.customer_name}</p>
      <p>Hotel Name: {bookingItem.hotel_name}</p>
      <p>Contact Type: {bookingItem.contact_type}</p>
      <p>Email: {bookingItem.customer_email}</p>
      <p>Phone: {bookingItem.customer_phone}</p>
      <div className="w-full h-[1px] bg-gray-200 mt-4"> </div>
      <div className="w-full h-10 flex flex-row items-center ">
        <GiCancel
          className="flex-1 my-auto h-5 w-5 fill-gray-500 hover:cursor-pointer"
          onClick={() => {}}
        ></GiCancel>

        <div className="h-6 my-auto w-[1px] bg-gray-200"></div>

        <FaCheck
          className="flex-1 my-auto h-5 w-5 fill-gray-500 hover:cursor-pointer"
          onClick={() => {
            onSelect(bookingItem);
          }}
        ></FaCheck>
      </div>
    </div>
  );
}
